import {STATUS_LIST} from "../../constants/common/profile";

export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

export const FETCH_POSITIONS_START = 'FETCH_POSITIONS_START';
export const FETCH_POSITIONS_SUCCESS = 'FETCH_POSITIONS_SUCCESS';
export const FETCH_POSITIONS_FAIL = 'FETCH_POSITIONS_FAIL';

export const RESET_JOB_SEEKERS = 'RESET_JOB_SEEKERS';
export const FETCH_JOB_SEEKERS_START = 'FETCH_JOB_SEEKERS_START';
export const FETCH_JOB_SEEKERS_SUCCESS = 'FETCH_JOB_SEEKERS_SUCCESS';
export const FETCH_JOB_SEEKERS_FAIL = 'FETCH_JOB_SEEKERS_FAIL';
export const FETCH_ONE_JOB_SEEKERS_SUCCESS = 'FETCH_ONE_JOB_SEEKERS_SUCCESS';
export const CREATE_JOB_SEEKERS_START = 'CREATE_JOB_SEEKERS_START';
export const CREATE_JOB_SEEKERS_SUCCESS = 'CREATE_JOB_SEEKERS_SUCCESS';
export const CREATE_JOB_SEEKERS_FAIL = 'CREATE_JOB_SEEKERS_FAIL';
export const SAVE_JOB_SEEKERS_SUCCESS = 'SAVE_JOB_SEEKERS_SUCCESS';
export const FETCH_JOB_SEEKERS_SUMMARY_START = 'FETCH_JOB_SEEKERS_SUMMARY_START'
export const FETCH_JOB_SEEKERS_SUMMARY_SUCCESS = 'FETCH_JOB_SEEKERS_SUMMARY_SUCCESS'
export const FETCH_JOB_SEEKERS_SUMMARY_FAIL = 'FETCH_JOB_SEEKERS_SUMMARY_FAIL'
export const SEARCH_JOB_SEEKERS_START = 'SEARCH_JOB_SEEKERS_START'
export const SEARCH_JOB_SEEKERS_SUCCESS = 'SEARCH_JOB_SEEKERS_SUCCESS'
export const SEARCH_JOB_SEEKERS_FAIL = 'SEARCH_JOB_SEEKERS_FAIL'

export const FETCH_EMPLOYERS_SUMMARY_START = 'FETCH_EMPLOYERS_SUMMARY_START'
export const FETCH_EMPLOYERS_SUMMARY_SUCCESS = 'FETCH_EMPLOYERS_SUMMARY_SUCCESS'
export const FETCH_EMPLOYERS_SUMMARY_FAIL = 'FETCH_EMPLOYERS_SUMMARY_FAIL'

export const RESET_EMPLOYERS = 'RESET_EMPLOYERS'
export const FETCH_EMPLOYERS_START = 'FETCH_EMPLOYERS_START'
export const FETCH_EMPLOYERS_FAIL = 'FETCH_EMPLOYERS_FAIL'
export const CREATE_EMPLOYERS_START = 'CREATE_EMPLOYERS_START'
export const CREATE_EMPLOYERS_SUCCESS = 'CREATE_EMPLOYERS_SUCCESS'
export const CREATE_EMPLOYERS_FAIL = 'CREATE_EMPLOYERS_FAIL'
export const SAVE_EMPLOYERS_SUCCESS = 'SAVE_EMPLOYERS_SUCCESS'
export const FETCH_EMPLOYERS_SUCCESS = 'FETCH_EMPLOYERS_SUCCESS'
export const FETCH_ONE_EMPLOYERS_SUCCESS = 'FETCH_ONE_EMPLOYERS_SUCCESS'

export const FETCH_CATEGORIES_START = 'FETCH_CATEGORIES_START';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_FAIL = 'FETCH_CATEGORIES_FAIL';

export const FETCH_EDUCATION_FORM_START = 'FETCH_EDUCATION_FORM_START';
export const FETCH_EDUCATION_FORM_SUCCESS = 'FETCH_EDUCATION_FORM_SUCCESS';
export const FETCH_EDUCATION_FORM_FAIL = 'FETCH_EDUCATION_FORM_FAIL';

export const FETCH_EDUCATION_LEVEL_START = 'FETCH_EDUCATION_LEVEL_START';
export const FETCH_EDUCATION_LEVEL_SUCCESS = 'FETCH_EDUCATION_LEVEL_SUCCESS';
export const FETCH_EDUCATION_LEVEL_FAIL = 'FETCH_EDUCATION_LEVEL_FAIL';

export const FETCH_EDUCATION_YEAR_START = 'FETCH_EDUCATION_YEAR_START';
export const FETCH_EDUCATION_YEAR_SUCCESS = 'FETCH_EDUCATION_YEAR_SUCCESS';
export const FETCH_EDUCATION_YEAR_FAIL = 'FETCH_EDUCATION_YEAR_FAIL';

export const FETCH_EMPLOYMENT_STABILITY_START = 'FETCH_EMPLOYMENT_STABILITY_START';
export const FETCH_EMPLOYMENT_STABILITY_SUCCESS = 'FETCH_EMPLOYMENT_STABILITY_SUCCESS';
export const FETCH_EMPLOYMENT_STABILITY_FAIL = 'FETCH_EMPLOYMENT_STABILITY_FAIL';

export const FETCH_EMPLOYMENT_TYPE_START = 'FETCH_EMPLOYMENT_TYPE_START';
export const FETCH_EMPLOYMENT_TYPE_SUCCESS = 'FETCH_EMPLOYMENT_TYPE_SUCCESS';
export const FETCH_EMPLOYMENT_TYPE_FAIL = 'FETCH_EMPLOYMENT_TYPE_FAIL';

export const FETCH_EXPERIENCE_START = 'FETCH_EXPERIENCE_START';
export const FETCH_EXPERIENCE_SUCCESS = 'FETCH_EXPERIENCE_SUCCESS';
export const FETCH_EXPERIENCE_FAIL = 'FETCH_EXPERIENCE_FAIL';

export const FETCH_FACULTIES_START = 'FETCH_FACULTIES_START';
export const FETCH_FACULTIES_SUCCESS = 'FETCH_FACULTIES_SUCCESS';
export const FETCH_FACULTIES_FAIL = 'FETCH_FACULTIES_FAIL';

export const FETCH_SPECIALITIES_START = 'FETCH_SPECIALITIES_START';
export const FETCH_SPECIALITIES_SUCCESS = 'FETCH_SPECIALITIES_SUCCESS';
export const FETCH_SPECIALITIES_FAIL = 'FETCH_SPECIALITIES_FAIL';
export const FETCH_SPECIALITIES_BY_FACULTY_SUCCESS = 'FETCH_SPECIALITIES_BY_FACULTY_SUCCESS';

export const FETCH_VACANCIES_START = 'FETCH_VACANCIES_START';
export const FETCH_VACANCIES_SUCCESS = 'FETCH_VACANCIES_SUCCESS';
export const RESET_VACANCIES = 'RESET_VACANCIES';
export const FETCH_ONE_VACANCIES_SUCCESS = 'FETCH_ONE_VACANCIES_SUCCESS';
export const FETCH_VACANCIES_FAIL = 'FETCH_VACANCIES_FAIL';
export const CREATE_VACANCIES_START = 'CREATE_VACANCIES_START';
export const CREATE_VACANCIES_SUCCESS = 'CREATE_VACANCIES_SUCCESS';
export const CREATE_VACANCIES_FAIL = 'CREATE_VACANCIES_FAIL';
export const SAVE_VACANCIES_SUCCESS = 'SAVE_VACANCIES_SUCCESS';
export const SEARCH_VACANCIES_START = 'SEARCH_VACANCIES_START';
export const SEARCH_VACANCIES_SUCCESS = 'SEARCH_VACANCIES_SUCCESS';
export const SEARCH_VACANCIES_FAIL = 'SEARCH_VACANCIES_FAIL';
export const SET_VACANCY_FILTER = 'SET_VACANCY_FILTER';

export const VacancyFilters = {
  SHOW_ALL: 'SHOW_ALL',
  SHOW_ACTIVE: STATUS_LIST.ACTIVE
}

export const FETCH_MEETINGS_START = 'FETCH_MEETINGS_START';
export const FETCH_MEETINGS_SUCCESS = 'FETCH_MEETINGS_SUCCESS';
export const FETCH_ONE_MEETINGS_SUCCESS = 'FETCH_ONE_MEETINGS_SUCCESS';
export const FETCH_MEETINGS_FAIL = 'FETCH_MEETINGS_FAIL';
export const CREATE_MEETINGS_START = 'CREATE_MEETINGS_START';
export const CREATE_MEETINGS_SUCCESS = 'CREATE_MEETINGS_SUCCESS';
export const CREATE_MEETINGS_FAIL = 'CREATE_MEETINGS_FAIL';
export const SAVE_MEETINGS_SUCCESS = 'SAVE_MEETINGS_SUCCESS';
export const SEARCH_MEETINGS_START = 'SEARCH_MEETINGS_START';
export const SEARCH_MEETINGS_SUCCESS = 'SEARCH_MEETINGS_SUCCESS';
export const SEARCH_MEETINGS_FAIL = 'SEARCH_MEETINGS_FAIL'
export const RESET_MEETINGS = 'RESET_MEETINGS';
export const SET_MEETING_FILTER = 'SET_MEETING_FILTER';

export const RESET_ALL='RESET_ALL';
export const RESET_PAGE='RESET_PAGE';
export const SET_PAGE_TAGS='SET_PAGE_TAGS';
export const RESET_PAGE_TAGS='RESET_PAGE_TAGS';
export const SET_PAGE_ACTIONS='SET_PAGE_ACTIONS';
export const RESET_PAGE_ACTIONS='RESET_PAGE_ACTIONS';
export const SET_PAGE_PATH_REDIRECT='SET_PAGE_PATH_REDIRECT';
export const RESET_PAGE_PATH_REDIRECT='RESET_PAGE_PATH_REDIRECT';

export const FETCH_ONE_USERS_SUCCESS='FETCH_ONE_USERS_SUCCESS';
export const SAVE_USERS_SUCCESS ='SAVE_USERS_SUCCESS';
export const FETCH_USERS_START='FETCH_USERS_START';
export const FETCH_USERS_FAIL='FETCH_USERS_FAIL';
export const SEARCH_USERS_START='SEARCH_USERS_START';
export const SEARCH_USERS_SUCCESS='SEARCH_USERS_SUCCESS';
export const SEARCH_USERS_FAIL='SEARCH_USERS_FAIL';
export const RESET_USERS ='RESET_USERS';

export const MeetingFilters = {
  SHOW_ALL: 'SHOW_ALL',
  SHOW_EXPIRED: 'SHOW_EXPIRED',
  SHOW_REQUESTS: 'SHOW_REQUESTS',
  SHOW_FEATURE: 'SHOW_FEATURE'
}

export const UPLOAD_FILE_START = 'UPLOAD_FILE_START';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAIL = 'UPLOAD_FILE_FAIL';
export const UPLOAD_FILE_RESET = 'UPLOAD_FILE_RESET';
export const DOWNLOADED_FILE_SUCCESS = 'DOWNLOADED_FILE_SUCCESS';