export const USER = 'Користувач';
export const SUCCESS_SAVED = "Збережено";
export const WELCOME = "Вітаємо";
export const CONTINUE = 'Продовжити'
export const GO_BACK = 'Назад';
export const NOT_FOUND_PAGE = 'Сторінку не знайдено';
export const SAVE = 'Зберегти';
export const UPDATE = 'Оновити';
export const SELECT_OPTION = '- Вибрати -';
export const ANOTHER_OPTION = 'Інше';
export const EMPTY_DATA = 'Немає данних для відображення'
export const CREATED = 'Створено'
export const UPDATED = 'Оновлено'
export const SEARCH = 'Пошук'
export const SEARCH_HELPER = 'Введіть ім\'я, прізвище або email користувача'
export const EDITING = 'Редагування';
export const EDIT = 'Редагувати';
export const MORE = 'Детальніше';
