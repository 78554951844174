import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../util/utility';

const initialState = {
  loading: false,
  error: null,
  object: {},
  list: []
};

const fetchStart = (state, action) => {
  return updateObject(state, {error: null, loading: true});
};

const fetchSuccess = (state, action) => {
  return updateObject(state, {
    list: action.list,
    error: null,
    loading: false
  });
};

const fetchFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    list: [],
    loading: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_EMPLOYMENT_TYPE_START:
      return fetchStart(state, action);
    case actionTypes.FETCH_EMPLOYMENT_TYPE_SUCCESS:
      return fetchSuccess(state, action);
    case actionTypes.FETCH_EMPLOYMENT_TYPE_FAIL:
      return fetchFail(state, action);
    default:
      return state;
  }
};

export default reducer;