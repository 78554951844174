import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../util/utility';

const initialState = {
  pathRedirect: '',
  positions: [],
  filter: '',
  loading: false,
  error: null,
  object: {},
  list: []
};

const fetchVacanciesStart = (state, action) => {
  return updateObject(state, {error: null, loading: true});
};

const fetchVacanciesSuccess = (state, action) => {
  return updateObject(state, {
    list: action.list,
    error: null,
    loading: false
  });
};

const fetchOneVacanciesSuccess = (state, action) => {
  return updateObject(state, {
    object: action.object,
    error: null,
    loading: false
  });
};

const fetchVacanciesFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    list: [],
    loading: false
  });
};

const fetchPositionsSuccess = (state, action) => {
  return updateObject(state, {
    positions: action.list,
    error: null,
    loading: false
  });
};

const fetchPositionsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    positions: [],
    loading: false
  });
};

const searchVacanciesSuccess = (state, action) => {
  return updateObject(state, {
    list: action.list,
    error: null,
    loading: false
  });
};

const searchVacanciesFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    list: [],
    loading: false
  });
};

const createVacanciesStart = (state, action) => {
  return updateObject(state, {error: null, loading: true});
};

const createVacanciesSuccess = (state, action) => {
  return updateObject(state, {
    pathRedirect: action.pathRedirect,
    error: null,
    loading: false
  });
};

const saveVacanciesSuccess = (state, action) => {
  return updateObject(state, {
    pathRedirect: action.pathRedirect,
    error: null,
    loading: false
  });
};

const createVacanciesFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false
  });
};

const setVacanciesFilter = (state, action) => {
  return updateObject(state, {
    error: null,
    filter: action.filter,
    list: [],
    loading: false
  });
};

const resetVacancies = (state) => {
  return updateObject(state, initialState);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_VACANCIES_START:
      return fetchVacanciesStart(state, action);
    case actionTypes.FETCH_VACANCIES_SUCCESS:
      return fetchVacanciesSuccess(state, action);
    case actionTypes.FETCH_ONE_VACANCIES_SUCCESS:
      return fetchOneVacanciesSuccess(state, action);
    case actionTypes.FETCH_VACANCIES_FAIL:
      return fetchVacanciesFail(state, action);
    case actionTypes.CREATE_VACANCIES_START:
      return createVacanciesStart(state, action);
    case actionTypes.CREATE_VACANCIES_SUCCESS:
      return createVacanciesSuccess(state, action);
    case actionTypes.SAVE_VACANCIES_SUCCESS:
      return saveVacanciesSuccess(state, action);
    case actionTypes.CREATE_VACANCIES_FAIL:
      return createVacanciesFail(state, action);
    case actionTypes.RESET_VACANCIES:
      return resetVacancies(state, action);
    case actionTypes.FETCH_POSITIONS_START:
      return fetchVacanciesStart(state, action);
    case actionTypes.FETCH_POSITIONS_SUCCESS:
      return fetchPositionsSuccess(state, action);
    case actionTypes.FETCH_POSITIONS_FAIL:
      return fetchPositionsFail(state, action);
    case actionTypes.SEARCH_VACANCIES_START:
      return fetchVacanciesStart(state, action);
    case actionTypes.SEARCH_VACANCIES_SUCCESS:
      return searchVacanciesSuccess(state, action);
    case actionTypes.SEARCH_VACANCIES_FAIL:
      return searchVacanciesFail(state, action);
    case actionTypes.SET_VACANCY_FILTER:
      return setVacanciesFilter(state, action);
    default:
      return state;
  }
};

export default reducer;