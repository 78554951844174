import React from 'react';
import { Spin, Icon } from 'antd';

const LoadingIndicator = (props) => {
  const antIcon = <Icon type="loading-3-quarters" style={{ fontSize: 30 }} spin />;
  return (
    <div className='custom-container'>
      <div className='custom-content'>
        <Spin indicator={antIcon} style = {{display: 'block', textAlign: 'center', marginTop: 30}} />
      </div>
    </div>
  );
}

export default LoadingIndicator;