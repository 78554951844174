import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import {Layout, Menu, Dropdown, Icon} from 'antd';

import {BRAND, EMPLOYERS, JOBSEEKERS, MAIN_MENU_DROPDOWN, roles} from "../../constants/common/common";
import {vacancyPath, meetingPath, resumePath, employerPath} from "../../constants/path";
import {ALLOWED_ROLES, PROFILE} from "../../constants/common/profile";
import {LOGIN, LOGOUT, SIGN_UP} from "../../constants/common/auth";
import {VACANCIES} from "../../constants/common/vacancy";
import {MEETINGS} from "../../constants/common/meeting";
import {getUserName} from "../../util/userHelpers";
import * as types from '../../util/variableTypes';
import {getRole} from "../../util/userHelpers";

import logo from '../../assets/images/logo-xs.png'
import './appHeader.css';

const {isString, isEmptyString, isObject, isEmptyObject} = types;

const Header = Layout.Header;

const menuInit = [
  <Menu.Item key="/login">
    <Link to="/login">{LOGIN}</Link>
  </Menu.Item>,
  <Menu.Item key="/signup">
    <Link to="/signup">{SIGN_UP}</Link>
  </Menu.Item>
];

class AppHeader extends Component {

  state = { menuAdmin: [], menuJobSeeker: [], menuEmployer: [] }

  handleMenuClick = ({key}) => {
    if (key === "logout") {
      this.props.onLogout();
    }
  };

  render() {
    const {authenticated, me, browser}  = this.props;
    const menu = [];
    const role = getRole(me);
    const { role: {name = ''} = {} } = me || {};
    const menuAdmin = [
      <Menu.Item key={vacancyPath.path.search}>
        <Link to={vacancyPath.path.search}>{VACANCIES}</Link>
      </Menu.Item>,
      <Menu.Item
          key={/^\/meetings/.test(this.props.location.pathname) ? this.props.location.pathname : meetingPath.path.list}
      >
        <Link to={meetingPath.path.feature}>{MEETINGS}</Link>
      </Menu.Item>,
      <Menu.Item key={resumePath.path.list}>
        <Link to={resumePath.path.list}>{JOBSEEKERS}</Link>
      </Menu.Item>,
      <Menu.Item key={employerPath.path.list}>
        <Link to={employerPath.path.list}>{EMPLOYERS}</Link>
      </Menu.Item>
    ];
    const menuJobSeeker = [
      <Menu.Item key={vacancyPath.path.search}>
        <Link to={vacancyPath.path.search}>{VACANCIES}</Link>
      </Menu.Item>,
      <Menu.Item
          key={/^\/meetings/.test(this.props.location.pathname) ? this.props.location.pathname : meetingPath.path.list}
      >
        <Link to={meetingPath.path.feature}>{MEETINGS}</Link>
      </Menu.Item>
    ];

    const menuEmployer = [
      <Menu.Item key={resumePath.path.list}>
        <Link to={resumePath.path.list}>{JOBSEEKERS}</Link>
      </Menu.Item>,
      <Menu.Item key={vacancyPath.path.list}>
        <Link to={vacancyPath.path.list}>{VACANCIES}</Link>
      </Menu.Item>,
      <Menu.Item
          key={/^\/meetings/.test(this.props.location.pathname) ? this.props.location.pathname : meetingPath.path.list}
      >
        <Link to={meetingPath.path.feature}>{MEETINGS}</Link>
      </Menu.Item>
    ];
    if (!authenticated) {
      if (browser.lessThan.medium) {
        menu.push(menuInit[0])
      } else {
        menu.push(...menuInit)
      }

    } else {
      if (!(isString(role) && !isEmptyString(role)) || !ALLOWED_ROLES.includes(role)) {
        menu.push(
          <Menu.Item key="logout" className="dropdown-item">
            {LOGOUT}
          </Menu.Item>
        )
      } else {
        const menuByType = []
        if (name === 'ADMIN') {
          menuByType.push(...menuAdmin)
        }
        if (name === 'JOBSEEKER') {
          menuByType.push(...menuJobSeeker)
        }
        if (name === 'EMPLOYER') {
          menuByType.push(...menuEmployer)
        }

        if (!browser.lessThan.medium) {
          menu.push(...menuByType)
        } else {
          menu.push(menuByType[0])
        }

        menu.push(
          <Menu.Item key="/profile" className="profile-menu" >
            <ProfileDropdownMenu
              me={me}
              handleMenuClick={this.handleMenuClick}>
              {browser.lessThan.medium ? menuByType.splice(1) : null}
            </ProfileDropdownMenu>
          </Menu.Item>
        )
      }
    }
    return (
      <Header className="app-header">
        <div className="container">
          <div className="app-branding">
            <Link to="/" className="app-title">
              <img src={logo} alt={BRAND}/>
            </Link>
          </div>
          <Menu
            theme='dark'
            className="app-menu"
            mode="horizontal"
            selectedKeys={[this.props.location.pathname]}
            style={{lineHeight: '64px'}}>
            {menu}
          </Menu>
        </div>
      </Header>
    )
  }
}

const ProfileDropdownMenu = ({ me, handleMenuClick, children}) => {
  if (!isObject(me) && !isEmptyObject(me)) return null;
  const { email, role: {name = ''} = {} } = me || {};
  const userName = getUserName(me)
  const dropdownMenu = (
    <Menu onClick={handleMenuClick} className="profile-dropdown-menu">
      <Menu.Item key="user-info" className="dropdown-item" disabled>
        <div className="user-full-name-info">
          {userName}
        </div>
        <div className="username-info">
          {email}
        </div>
        <div className="username-info" style={{ color: 'cornflowerblue' }}>
          {roles[name] || ''}
        </div>
      </Menu.Item>
      <Menu.Divider/>
      <Menu.Item key="/profile">
        <Link to="/profile">{PROFILE}</Link>
      </Menu.Item>
      {
        MAIN_MENU_DROPDOWN.map((btn, i) => (
          <Menu.Item key={btn.key} className="dropdown-item">
            <a href={btn.url}>{btn.title}</a>
          </Menu.Item>)
        )
      }
      {children}
      <Menu.Item key="logout" className="dropdown-item">
        {LOGOUT}
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown
      overlay={dropdownMenu}
      trigger={['click']}
      getPopupContainer={() => document.getElementsByClassName('profile-menu')[0]}
    >
      <a className="ant-dropdown-link" href='#top'>
        <Icon type="user" className="nav-icon" style={{marginRight: 0}}/> <Icon type="down"/>
      </a>
    </Dropdown>
  );
}

export default withRouter(AppHeader);