import React, {Component} from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import {Layout, notification} from 'antd';
import {connect} from 'react-redux';

import {vacancyPath, resumePath, profilePath, employerPath, meetingPath} from './constants/path';
import OAuth2RedirectHandler from './containers/User/Oauth2/OAuth2RedirectHandler';
import LoadingIndicator from './components/UI/LoadingIndicator/LoadingIndicator';
import PrivateRoute from "./components/Common/PrivateRoute";
import AppHeader from "./containers/AppHeader/AppHeader";
import NotFound from "./components/NotFound/NotFound";
import Login from "./containers/User/Login/Login";
import * as actions from './store/actions/index';
import {FOOTER} from './constants/common/common';
import {getAccessToken} from './util/APIUtils';
import Home from './containers/Home/Home';
import {
  asyncEmployerCreate, asyncEmployerEdit, asyncJobSeekerCard,
  asyncProfile, asyncSignup, asyncVacancy, asyncVacancyCreate,
  asyncJobSeekerCreate, asyncJobSeekerEdit, asyncJobSeekerSearch,
  asyncMeetingCreate, asyncMeetingEdit, asyncMeetingList, asyncMeetingView,
  asyncVacancyEdit, asyncVacancyList, asyncVacancySearch, asyncEmployerSearch, asyncUserEdit, asyncProfilesSearch
} from './asyncLoader';

import 'antd/dist/antd.css';
import './App.css';

const {Content, Footer} = Layout;

class App extends Component {

    componentDidMount() {
        this.props.onTryAutoSignup();
        notification.config({
            placement: 'topRight',
            top: 70,
            duration: 3,
        });
    }

    render() {
        const {loading, me, onLogout, browser, page} = this.props;
        const isAuthenticated = getAccessToken() || false

        return (
            <Layout className="app-container">
                <div className="app-top-box">
                    <AppHeader
                        me={me}
                        browser={browser}
                        onLogout={onLogout}
                        authenticated={isAuthenticated}
                    />
                </div>
                <Content className="app-content">
                    <div className="container">
                        {
                            loading ? (
                                <LoadingIndicator/>
                            ) : (

                                <Switch>
                                    <Route exact path="/" component={Home}/>
                                    {/* PROFILE */}
                                    <PrivateRoute me={me}
                                                  {...page}
                                                  {...profilePath.items.credential}
                                                  authenticated={isAuthenticated}
                                                  component={asyncUserEdit}
                                    />
                                    <PrivateRoute me={me}
                                                  {...page}
                                                  {...profilePath.items.create}
                                                  authenticated={isAuthenticated}
                                                  component={asyncJobSeekerCreate}
                                    />
                                    <PrivateRoute me={me}
                                                  {...page}
                                                  {...profilePath.items.edit}
                                                  authenticated={isAuthenticated}
                                                  component={asyncJobSeekerEdit}
                                    />
                                    <PrivateRoute me={me}
                                                  {...page}
                                                  {...profilePath.items.view}
                                                  authenticated={isAuthenticated}
                                                  component={asyncProfile}
                                    />
                                    <PrivateRoute me={me}
                                                  {...page}
                                                  {...profilePath.items.list}
                                                  authenticated={isAuthenticated}
                                                  component={asyncProfilesSearch}
                                    />
                                    {/* VACANCIES */}
                                    <PrivateRoute me={me}
                                                  {...page}
                                                  component={asyncVacancy}
                                                  authenticated={isAuthenticated}
                                                  {...vacancyPath.items.searchOne}
                                    />
                                    <PrivateRoute me={me}
                                                  {...page}
                                                  {...vacancyPath.items.search}
                                                  component={asyncVacancySearch}
                                                  authenticated={isAuthenticated}
                                    />
                                    <PrivateRoute me={me}
                                                  {...page}
                                                  {...vacancyPath.items.create}
                                                  component={asyncVacancyCreate}
                                                  authenticated={isAuthenticated}
                                    />
                                    <PrivateRoute me={me}
                                                  {...page}
                                                  {...vacancyPath.items.edit}
                                                  component={asyncVacancyEdit}
                                                  authenticated={isAuthenticated}
                                    />
                                    <PrivateRoute me={me}
                                                  {...page}
                                                  component={asyncVacancyList}
                                                  {...vacancyPath.items.active}
                                                  authenticated={isAuthenticated}
                                    />
                                    <PrivateRoute me={me}
                                                  {...page}
                                                  component={asyncVacancy}
                                                  {...vacancyPath.items.view}
                                                  authenticated={isAuthenticated}
                                    />
                                    <PrivateRoute me={me}
                                                  {...page}
                                                  component={asyncVacancyList}
                                                  {...vacancyPath.items.list}
                                                  authenticated={isAuthenticated}
                                    />
                                    {/* RESUMES */}
                                    <PrivateRoute me={me}
                                                  {...page}
                                                  {...resumePath.items.create}
                                                  authenticated={isAuthenticated}
                                                  component={asyncJobSeekerCreate}
                                    />
                                    <PrivateRoute me={me}
                                                  {...page}
                                                  {...resumePath.items.edit}
                                                  authenticated={isAuthenticated}
                                                  component={asyncJobSeekerEdit}
                                    />
                                    <PrivateRoute me={me}
                                                  {...page}
                                                  {...resumePath.items.view}
                                                  authenticated={isAuthenticated}
                                                  component={asyncJobSeekerCard}
                                    />
                                    <PrivateRoute me={me}
                                                  {...page}
                                                  {...resumePath.items.list}
                                                  component={asyncJobSeekerSearch}
                                                  authenticated={isAuthenticated}
                                    />
                                    {/* EMPLOYERS */}
                                    <PrivateRoute me={me}
                                                  {...page}
                                                  {...employerPath.items.create}
                                                  authenticated={isAuthenticated}
                                                  component={asyncEmployerCreate}
                                    />
                                    <PrivateRoute me={me}
                                                  {...page}
                                                  {...employerPath.items.edit}
                                                  authenticated={isAuthenticated}
                                                  component={asyncEmployerEdit}
                                    />
                                    <PrivateRoute me={me}
                                                  {...page}
                                                  {...employerPath.items.list}
                                                  authenticated={isAuthenticated}
                                                  component={asyncEmployerSearch}
                                    />
                                    {/* MEETINGS */}
                                    <PrivateRoute me={me}
                                                  {...page}
                                                  {...meetingPath.items.create}
                                                  authenticated={isAuthenticated}
                                                  component={asyncMeetingCreate}
                                    />
                                    <PrivateRoute me={me}
                                                  {...page}
                                                  {...meetingPath.items.edit}
                                                  authenticated={isAuthenticated}
                                                  component={asyncMeetingEdit}
                                    />
                                    <PrivateRoute me={me}
                                                  {...page}
                                                  {...meetingPath.items.createByJobSeeker}
                                                  authenticated={isAuthenticated}
                                                  component={asyncMeetingCreate}
                                    />
                                    <PrivateRoute me={me}
                                                  {...page}
                                                  {...meetingPath.items.feature}
                                                  authenticated={isAuthenticated}
                                                  component={asyncMeetingList}
                                    />
                                    <PrivateRoute me={me}
                                                  {...page}
                                                  {...meetingPath.items.requests}
                                                  authenticated={isAuthenticated}
                                                  component={asyncMeetingList}
                                    />
                                    <PrivateRoute me={me}
                                                  {...page}
                                                  {...meetingPath.items.expired}
                                                  authenticated={isAuthenticated}
                                                  component={asyncMeetingList}
                                    />
                                    <PrivateRoute me={me}
                                                  {...page}
                                                  {...meetingPath.items.view}
                                                  authenticated={isAuthenticated}
                                                  component={asyncMeetingView}
                                    />
                                    <PrivateRoute me={me}
                                                  {...page}
                                                  component={asyncMeetingList}
                                                  {...meetingPath.items.list}
                                                  authenticated={isAuthenticated}
                                    />
                                    <Route path="/login" component={Login}/>
                                    <Route path="/signup" component={asyncSignup}/>
                                    <Route path="/oauth2/redirect" component={OAuth2RedirectHandler}/>
                                    <Route component={NotFound}/>
                                </Switch>
                            )
                        }
                    </div>
                </Content>
                <Footer style={{textAlign: 'center'}}>{FOOTER}</Footer>
            </Layout>
        )
    }
}

const mapStateToProps = state => {
    return {
        page: state.page,
        me: state.auth.me,
        browser: state.browser,
        error: state.auth.error,
        loading: state.auth.loading,
        isAuthenticated: state.auth.token !== null
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onTryAutoSignup: () => dispatch(actions.authCheckState()),
        onLogout: () => dispatch(actions.logout())
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
