import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../util/utility';

const initialState = {
  tags: [],
  actions: [],
  subTitle: '',
  pathRedirect: '',
  allowedPath: []
};

const setPageActions = (state, action) => {
  return updateObject(state, {
    actions: action.list
  });
};

const setPagePathRedirect = (state, action) => {
  return updateObject(state, {
    pathRedirect: action.value[0] || '',
    allowedPath: action.value[1] || []
  });
};

const setPageTags = (state, action) => {
  return updateObject(state, {
    tags: action.list
  });
};

const resetPage = (state) => {
  const newState = {
    ...initialState,
    pathRedirect: state.pathRedirect, allowedPath: state.allowedPath
  }
  return updateObject(state, newState);
};

const resetPagePathRedirect = (state) => {
  return updateObject(state, {
    pathRedirect: '',
    allowedPath: []
  });
};

const resetPageActions = (state) => {
  return updateObject(state, {
    actions: []
  });
};

const resetPageTags = (state) => {
  return updateObject(state, {
    tags: []
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_PAGE_ACTIONS:
      return setPageActions(state, action);
    case actionTypes.SET_PAGE_PATH_REDIRECT:
      return setPagePathRedirect(state, action);
    case actionTypes.SET_PAGE_TAGS:
      return setPageTags(state, action);
    case actionTypes.RESET_PAGE_PATH_REDIRECT:
      return resetPagePathRedirect(state);
    case actionTypes.RESET_PAGE:
      return resetPage(state);
    case actionTypes.RESET_PAGE_ACTIONS:
      return resetPageActions(state);
    case actionTypes.RESET_PAGE_TAGS:
      return resetPageTags(state);
    default:
      return state;
  }
};

export default reducer;
