import {EDITING} from "../common/notification";
import {HOME, JOBSEEKERS} from "../common/common";
import {NEW_PROFILE, PROFILE} from "../common/profile";
import profilePath from "./profile";

const path = {
  create: "/resumes/create",
  edit: "/resumes/edit/:id",
  view: "/resumes/:id",
  list: "/resumes",
}

const items = {
  create: {
    title: NEW_PROFILE,
    path: path.create,
    allowedRoles: ['ADMIN', 'JOBSEEKER'],
    breadcrumb: [
      {to: '/', label: HOME},
      {to: path.list, label: PROFILE},
      {to: path.create, label: NEW_PROFILE}
    ]
  },
  edit: {
    title: EDITING,
    path: path.edit,
    allowedRoles: ['ADMIN', 'JOBSEEKER'],
    breadcrumb: [
      {to: '/', label: HOME},
      {to: path.list, label: PROFILE},
      {to: path.edit, label: EDITING}
    ]
  },
  view: {
    title: PROFILE,
    path: path.view,
    redirect: profilePath.path.view,
    allowedRoles: ['ADMIN', 'EMPLOYER'],
    breadcrumb: [
      {to: '/', label: HOME},
      {to: path.list, label: JOBSEEKERS},
      {to: path.view, label: PROFILE}
    ]
  },
  list: {
    title: JOBSEEKERS,
    path: path.list,
    redirect: profilePath.path.view,
    allowedRoles: ['ADMIN', 'EMPLOYER']
  },
};

export default {path, items}
