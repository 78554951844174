import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../util/utility';

const initialState = {
  pathRedirect: '',
  summaryList: [],
  loading: false,
  error: null,
  object: {},
  list: []
};

const fetchEmployerStart = (state, action) => {
  return updateObject(state, {error: null, loading: true});
};

const fetchJobSeekersSuccess = (state, action) => {
  return updateObject(state, {
    list: action.list,
    error: null,
    loading: false
  });
};


const fetchOneEmployerSuccess = (state, action) => {
  return updateObject(state, {
    object: action.object,
    error: null,
    loading: false
  });
};

const fetchEmployersFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    list: [],
    loading: false
  });
};

const createEmployerStart = (state, action) => {
  return updateObject(state, {error: null, loading: true});
};

const createEmployerSuccess = (state, action) => {
  return updateObject(state, {
    pathRedirect: action.pathRedirect,
    error: null,
    loading: false
  });
};

const saveEmployerSuccess = (state, action) => {
  return updateObject(state, {
    pathRedirect: action.pathRedirect,
    error: null,
    loading: false
  });
};

const createEmployerFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    list: [],
    loading: false
  });
};

const resetEmployers = (state) => {
  return updateObject(state, initialState);
};

const fetchEmployersSummaryStart = (state, action) => {
  return updateObject(state, {error: null, loading: true});
};

const fetchEmployersSummarySuccess = (state, action) => {
  return updateObject(state, {
    summaryList: action.list,
    error: null,
    loading: false
  });
};

const fetchEmployersSummaryFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    summaryList: [],
    loading: false
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_EMPLOYERS_START:
      return fetchEmployerStart(state, action);
    case actionTypes.FETCH_EMPLOYERS_SUCCESS:
      return fetchJobSeekersSuccess(state, action);
    case actionTypes.FETCH_ONE_EMPLOYERS_SUCCESS:
      return fetchOneEmployerSuccess(state, action);
    case actionTypes.FETCH_EMPLOYERS_FAIL:
      return fetchEmployersFail(state, action);
    case actionTypes.CREATE_EMPLOYERS_START:
      return createEmployerStart(state, action);
    case actionTypes.CREATE_EMPLOYERS_SUCCESS:
      return createEmployerSuccess(state, action);
    case actionTypes.SAVE_EMPLOYERS_SUCCESS:
      return saveEmployerSuccess(state, action);
    case actionTypes.CREATE_EMPLOYERS_FAIL:
      return createEmployerFail(state, action);
    case actionTypes.RESET_EMPLOYERS:
      return resetEmployers(state, action);
    case actionTypes.FETCH_EMPLOYERS_SUMMARY_START:
      return fetchEmployersSummaryStart(state, action);
    case actionTypes.FETCH_EMPLOYERS_SUMMARY_SUCCESS:
      return fetchEmployersSummarySuccess(state, action);
    case actionTypes.FETCH_EMPLOYERS_SUMMARY_FAIL:
      return fetchEmployersSummaryFail(state, action);
    default:
      return state;
  }
};

export default reducer;