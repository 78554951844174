import * as actionTypes from './actionTypes';
import { notification } from 'antd';

import { JobseekersApi } from "../../util/APIUtils";
import {SUCCESS_SAVED} from "../../constants/common/notification";
import {resetPagePathRedirect, setPagePathRedirect} from "./page";
import {badResponseHandler, onCatch} from "../../util/helpers";

const fetchJobSeekersStart = () => {
  return {
    type: actionTypes.FETCH_JOB_SEEKERS_START
  };
};

const fetchJobSeekersSuccess = (list) => {
  return {
    type: actionTypes.FETCH_JOB_SEEKERS_SUCCESS,
    list
  };
};

const fetchOneJobSeekerSuccess = (object) => {
  return {
    type: actionTypes.FETCH_ONE_JOB_SEEKERS_SUCCESS,
    object
  };
};

const fetchJobSeekersFail = (error) => {
  return {
    type: actionTypes.FETCH_JOB_SEEKERS_FAIL,
    error
  };
};

const createJobSeekersStart = () => {
  return {
    type: actionTypes.CREATE_JOB_SEEKERS_START
  };
};

const createJobSeekerSuccess = (pathRedirect) => {
  return {
    type: actionTypes.CREATE_JOB_SEEKERS_SUCCESS,
    pathRedirect
  };
};

const saveJobSeekerSuccess = (pathRedirect) => {
  return {
    type: actionTypes.SAVE_JOB_SEEKERS_SUCCESS,
    pathRedirect
  };
};

const createJobSeekerFail = (error) => {
  return {
    type: actionTypes.CREATE_JOB_SEEKERS_FAIL,
    error
  };
};

const fetchJobSeekersSummaryStart = () => {
  return {
    type: actionTypes.FETCH_JOB_SEEKERS_SUMMARY_START
  };
};

const fetchJobSeekersSummarySuccess = (list) => {
  return {
    type: actionTypes.FETCH_JOB_SEEKERS_SUMMARY_SUCCESS,
    list
  };
};

const fetchJobSeekersSummaryFail = (error) => {
  return {
    type: actionTypes.FETCH_JOB_SEEKERS_SUMMARY_FAIL,
    error
  };
};

const searchJobSeekersStart = () => {
  return {
    type: actionTypes.SEARCH_JOB_SEEKERS_START
  };
};

const searchJobSeekersSuccess = (list) => {
  return {
    type: actionTypes.SEARCH_JOB_SEEKERS_SUCCESS,
    list
  };
};

const searchJobSeekersFail = (error) => {
  return {
    type: actionTypes.SEARCH_JOB_SEEKERS_FAIL,
    error
  };
};

export const resetJobSeekers = () => {
  return {
    type: actionTypes.RESET_JOB_SEEKERS
  };
};

export const searchJobSeekers = (filter, page = 0, size) => {
  return dispatch => {
    dispatch(searchJobSeekersStart());
    JobseekersApi.search(filter, page, size)
      .then(response => {
        dispatch(searchJobSeekersSuccess(response));
      })
      .catch(error => {
        const m = onCatch(error);
        dispatch(searchJobSeekersFail(m));
      });
  };
};

export const searchJobSeekerIds = (filter) => {
  return dispatch => {
    dispatch(searchJobSeekersStart());
    JobseekersApi.searchIds(filter)
      .then(response => {
        dispatch(searchJobSeekersSuccess(response));
      })
      .catch(error => {
        const m = onCatch(error);
        dispatch(searchJobSeekersFail(m));
      });
  };
};

export const getJobSeekersSummary = () => {
  return dispatch => {
    dispatch(fetchJobSeekersSummaryStart());
    JobseekersApi.getJobSeekersSummary()
      .then(response => {
        dispatch(fetchJobSeekersSummarySuccess(response));
      })
      .catch(error => {
        const m = onCatch(error);
        dispatch(fetchJobSeekersSummaryFail(m));
      });
  };
};

export const getJobSeekerProfilesByUserId = (id) => {
  return dispatch => {
    dispatch(fetchJobSeekersStart());
    JobseekersApi.getAllByUser(id)
      .then(response => {
        dispatch(fetchJobSeekersSuccess(response));
      })
      .catch(error => {
        const m = onCatch(error);
        dispatch(fetchJobSeekersFail(m));
      });
  };
};

export const getActiveOneJobSeekerByUserId = (id, pathRedirect, allowedPath = []) => {
  return dispatch => {
    dispatch(fetchJobSeekersStart());
    JobseekersApi.getActiveOneByUserId(id)
      .then(response => {
        dispatch(resetPagePathRedirect());
        dispatch(fetchOneJobSeekerSuccess(response));
      })
      .catch(error => {
        const {message} = badResponseHandler(error);
        if (error && error.status === 400 && pathRedirect) {
          dispatch(setPagePathRedirect([pathRedirect, allowedPath]));
        }
        dispatch(fetchJobSeekersFail(message));
      });
  };
};

export const getJobSeekerActiveProfilesByUserId = (id) => {
  return dispatch => {
    dispatch(fetchJobSeekersStart());
    JobseekersApi.getAllActiveByUser(id)
      .then(response => {
        dispatch(fetchJobSeekersSuccess(response));
      })
      .catch(error => {
        const m = onCatch(error);
        dispatch(fetchJobSeekersFail(m));
      });
  };
};

export const getOneJobSeekerById = (id) => {
  return dispatch => {
    dispatch(fetchJobSeekersStart());
    JobseekersApi.getOneById(id)
      .then(response => {
        dispatch(fetchOneJobSeekerSuccess(response));
      })
      .catch(error => {
        const m = onCatch(error);
        dispatch(fetchJobSeekersFail(m));
      });
  };
};

export const createJobSeekerProfile = (object) => {
  return dispatch => {
    dispatch(createJobSeekersStart());
    JobseekersApi.create(object)
      .then(response => {
        const m = (response && response.response) || '';
        notification.success({
          message: SUCCESS_SAVED,
          description: m
        });
        dispatch(createJobSeekerSuccess('/profile'));
      })
      .catch(error => {
        const m = onCatch(error);
        dispatch(createJobSeekerFail(m));
      });
  };
};

export const saveJobSeekerProfile = (id, object) => {
  return dispatch => {
    dispatch(createJobSeekersStart());
    JobseekersApi.save(id, object)
      .then(response => {
        const m = (response && response.response) || '';
        notification.success({
          message: SUCCESS_SAVED,
          description: m
        });
        dispatch(saveJobSeekerSuccess('/profile'));
      })
      .catch(error => {
        const m = onCatch(error);
        dispatch(createJobSeekerFail(m));
      });
  };
};
