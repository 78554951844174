import {HOME} from "../common/common";
import {ARCHIVE} from "../common/buttons";
import {EDIT, SEARCH} from "../common/notification";
import {NEW_VACANCY, VACANCIES, VACANCY} from "../common/vacancy";

const path = {
  search: "/jobs",
  searchOne: "/jobs/:id",
  create: "/vacancies/create",
  active: "/vacancies/active",
  edit: "/vacancies/edit/:id",
  view: "/vacancies/:id",
  list: "/vacancies",
}

const items = {
  create: {
    title: NEW_VACANCY,
    path: path.create,
    allowedRoles: ['ADMIN', 'EMPLOYER'],
    breadcrumb: [
      {to: '/', label: HOME},
      {to: path.list, label: VACANCIES},
      {to: path.create, label: NEW_VACANCY}
    ]
  },
  edit: {
    title: EDIT,
    path: path.edit,
    allowedRoles: ['ADMIN', 'EMPLOYER'],
    breadcrumb: [
      {to: '/', label: HOME},
      {to: path.list, label: VACANCIES},
      {to: path.create, label: EDIT}
    ]
  },
  view: {
    title: VACANCY,
    path: path.view,
    redirect: path.search,
    allowedRoles: ['ADMIN', 'EMPLOYER'],
    breadcrumb: [
      {to: '/', label: HOME},
      {to: path.list, label: VACANCIES},
      {to: path.view, label: VACANCY}
    ]
  },
  search: {
    title: SEARCH,
    path: path.search,
    redirect: '/',
    allowedRoles: ['ADMIN', 'EMPLOYER', 'JOBSEEKER']
  },
  searchOne: {
    title: VACANCY,
    path: path.searchOne,
    redirect: path.search,
    allowedRoles: ['ADMIN', 'EMPLOYER', 'JOBSEEKER'],
    breadcrumb: [
      {to: '/', label: HOME},
      {to: path.search, label: VACANCIES},
      {to: path.searchOne, label: VACANCY}
    ]
  },
  list: {
    title: ARCHIVE,
    redirect: path.search,
    allowedRoles: ['ADMIN', 'EMPLOYER'],
    path: path.list,
    // breadcrumb: [
    //   {to: '/', label: HOME}
    // ]
  },
  active: {
    title: VACANCIES,
    redirect: path.search,
    allowedRoles: ['ADMIN', 'EMPLOYER'],
    path: path.list,
    // breadcrumb: [
    //   {to: '/', label: HOME}
    // ]
  }
}


export default {path, items}