import {isEmptyObject, isObject} from "./variableTypes";

export const getUserName = (object) => {
  if (isObject(object) && !isEmptyObject(object)) {
    const {name = {} } = object;
    const { lastName, middleName, firstName } = name;
    return ([lastName, middleName, firstName].filter(n => n) || []).join(' ');
  }
  return ''
};

export const calculateAge = (dob) => {
  const diff_ms = Date.now() - dob.getTime();
  const age_dt = new Date(diff_ms);

  return Math.abs(age_dt.getUTCFullYear() - 1970);
}

export const getRole = (object) => {
  if (isObject(object) && !isEmptyObject(object)) {
    const {role: {name: role = ''} = {} } = object;
    return role;
  }
  return ''
};
