export const RESPONSES = {
    500: 'Не вдалося отримати ресурс',
    400: 'Невірні дані',
    401: 'Авторизація не виконана',
};

export const NOT_FOUND = 'Not found';
export const FAILED_TO_FETCH = 'Failed to fetch';
export const BAD_CREDENTIALS = 'Bad credentials';
export const AGREE_TERMS = 'Agree to the terms and conditions is not confirmed';
export const MISSING_EMPLOYER = 'Missing employer';

export const RESPONSES_DESC = {
  [NOT_FOUND]: 'Спроба отримати дані, яких не існує',
  [FAILED_TO_FETCH]: 'Не вдалося отримати ресурс',
  [BAD_CREDENTIALS]: 'Невірний логін чи пароль. Якщо Ви ще не зареєстровані, пройдіть реєстранію',
  [AGREE_TERMS]: 'Не прийнята згода на обробку персональних данних.',
  [MISSING_EMPLOYER]: 'Анкета роботодавця не знайдена'
};

export const ERROR = 'Невдала спроба';
export const ERROR_DESC = 'На жаль, сталася помилка. Будь ласка, спробуйте ще';

