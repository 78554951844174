import * as actionTypes from './actionTypes';
import { notification } from 'antd';

import {profilePath} from '../../constants/path';
import {EmployersApi} from '../../util/APIUtils';
import {resetPagePathRedirect, setPagePathRedirect} from './page';
import {SUCCESS_SAVED} from '../../constants/common/notification';
import {onCatch} from "../../util/helpers";
import {MISSING_EMPLOYER, RESPONSES_DESC} from "../../constants/common/apiResponse";

export const fetchEmployersStart = () => {
  return {
    type: actionTypes.FETCH_EMPLOYERS_START
  };
};

const fetchEmployersSuccess = (list) => {
  return {
    type: actionTypes.FETCH_EMPLOYERS_SUCCESS,
    list
  };
};

export const fetchOneEmployerSuccess = (object) => {
  return {
    type: actionTypes.FETCH_ONE_EMPLOYERS_SUCCESS,
    object
  };
};

export const fetchEmployersFail = (error) => {
  return {
    type: actionTypes.FETCH_EMPLOYERS_FAIL,
    error
  };
};

const createEmployerStart = () => {
  return {
    type: actionTypes.CREATE_EMPLOYERS_START
  };
};

const createEmployerSuccess = (pathRedirect) => {
  return {
    type: actionTypes.CREATE_EMPLOYERS_SUCCESS,
    pathRedirect
  };
};

const createEmployerFail = (error) => {
  return {
    type: actionTypes.CREATE_EMPLOYERS_FAIL,
    error
  };
};

const saveEmployerSuccess = (pathRedirect) => {
  return {
    type: actionTypes.SAVE_EMPLOYERS_SUCCESS,
    pathRedirect
  };
};


export const resetEmployers = () => {
  return {
    type: actionTypes.RESET_EMPLOYERS
  };
};

const fetchEmployersSummaryStart = () => {
  return {
    type: actionTypes.FETCH_EMPLOYERS_SUMMARY_START
  };
};

const fetchEmployersSummarySuccess = (list) => {
  return {
    type: actionTypes.FETCH_EMPLOYERS_SUMMARY_SUCCESS,
    list
  };
};

const fetchEmployersSummaryFail = (error) => {
  return {
    type: actionTypes.FETCH_EMPLOYERS_SUMMARY_FAIL,
    error
  };
};

const searchEmployersStart = () => {
  return {
    type: actionTypes.FETCH_EMPLOYERS_START
  };
};

const searchEmployersSuccess = (list) => {
  return {
    type: actionTypes.FETCH_EMPLOYERS_SUCCESS,
    list
  };
};

const searchEmployersFail = (error) => {
  return {
    type: actionTypes.FETCH_EMPLOYERS_FAIL,
    error
  };
};

export const searchEmployers = (filter, page = 0, size) => {
  return dispatch => {
    dispatch(searchEmployersStart());
    EmployersApi.search(filter, page, size)
      .then(response => {
        dispatch(searchEmployersSuccess(response));
      })
      .catch(error => {
        const m = onCatch(error);
        dispatch(searchEmployersFail(m));
      });
  };
};

export const getEmployersSummary = () => {
  return dispatch => {
    dispatch(fetchEmployersSummaryStart());
    EmployersApi.getEmployersSummary()
      .then(response => {
        dispatch(fetchEmployersSummarySuccess(response));
      })
      .catch(error => {
        const m = onCatch(error);
        dispatch(fetchEmployersSummaryFail(m));
      });
  };
};

export const getAllEmployersByUserId = (id) => {
  return dispatch => {
    dispatch(fetchEmployersStart());
    EmployersApi.getAllByUser(id)
      .then(response => {
        dispatch(fetchEmployersSuccess(response));
      })
      .catch(error => {
        const m = onCatch(error);
        dispatch(fetchEmployersFail(m));
      });
  };
};

export const getAllActiveEmployersByUserId = (id) => {
  return dispatch => {
    dispatch(fetchEmployersStart());
    EmployersApi.getAllActiveByUser(id)
      .then(response => {
        dispatch(fetchEmployersSuccess(response));
      })
      .catch(error => {
        const m = onCatch(error);
        dispatch(fetchEmployersFail(m));
      });
  };
};

export const getOneEmployerById = (id) => {
  return dispatch => {
    dispatch(fetchEmployersStart());
    EmployersApi.getOneById(id)
      .then(response => {
        dispatch(fetchOneEmployerSuccess(response));
      })
      .catch(error => {
        const m = onCatch(error);
        dispatch(fetchEmployersFail(m));
      });
  };
};

export const getActiveOneEmployerByUserId = (id, pathRedirect, allowedPath = []) => {
  return dispatch => {
    dispatch(fetchEmployersStart());
    EmployersApi.getActiveOneByUserId(id)
      .then(response => {
        dispatch(fetchOneEmployerSuccess(response));
        dispatch(resetPagePathRedirect());
      })
      .catch(error => {
        const m = onCatch(error, RESPONSES_DESC[MISSING_EMPLOYER])
        if (error && error.status === 400 && pathRedirect) {
          dispatch(setPagePathRedirect([pathRedirect, allowedPath]));
        }
        dispatch(fetchEmployersFail(m));
      });
  };
};

export const createEmployerProfile = (object) => {
  return dispatch => {
    dispatch(createEmployerStart());
    EmployersApi.create(object)
      .then(response => {
        const m = (response && response.response) || '';
        notification.success({
          message: SUCCESS_SAVED,
          description: m
        });
        dispatch(createEmployerSuccess(profilePath.path.view));
      })
      .catch(error => {
        const m = onCatch(error);
        dispatch(createEmployerFail(m));
      });
  };
};

export const saveEmployerProfile = (id, object) => {
  return dispatch => {
    dispatch(createEmployerStart());
    EmployersApi.save(id, object)
      .then(response => {
        const m = (response && response.response) || '';
        notification.success({
          message: SUCCESS_SAVED,
          description: m
        });
        dispatch(saveEmployerSuccess('/profile'));
      })
      .catch(error => {
        const m = onCatch(error);
        dispatch(createEmployerFail(m));
      });
  };
};
