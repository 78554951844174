import * as actionTypes from './actionTypes';

import { getCategories } from "../../util/APIUtils";
import {onCatch} from "../../util/helpers";

const fetchStart = () => {
  return {
    type: actionTypes.FETCH_CATEGORIES_START
  };
};

const fetchSuccess = (list) => {
  return {
    type: actionTypes.FETCH_CATEGORIES_SUCCESS,
    list
  };
};

const fetchFail = (error) => {
  return {
    type: actionTypes.FETCH_CATEGORIES_FAIL,
    error
  };
};

export const getAllCategories = () => {
  return dispatch => {
    dispatch(fetchStart());
    getCategories()
      .then(response => {
        dispatch(fetchSuccess(response));
      })
      .catch(error => {
        const m = onCatch(error);
        dispatch(fetchFail(m));
      });
  };
};
