import * as actionTypes from './actionTypes';

import { getExperiences } from "../../util/APIUtils";
import {onCatch} from "../../util/helpers";


const fetchStart = () => {
  return {
    type: actionTypes.FETCH_EXPERIENCE_START
  };
};

const fetchSuccess = (list) => {
  return {
    type: actionTypes.FETCH_EXPERIENCE_SUCCESS,
    list
  };
};

const fetchFail = (error) => {
  return {
    type: actionTypes.FETCH_EXPERIENCE_FAIL,
    error
  };
};

export const getAllExperiences = () => {
  return dispatch => {
    dispatch(fetchStart());
    getExperiences()
      .then(response => {
        dispatch(fetchSuccess(response));
      })
      .catch(error => {
        const m = onCatch(error);
        dispatch(fetchFail(m));
      });
  };
};
