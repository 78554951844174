import {ACCESS_TOKEN, API_BASE_URL} from "../constants/common/auth";

export const getAccessToken = () => (
  localStorage.getItem(ACCESS_TOKEN)
);

const request = (options) => {
  const headers = new Headers({
    'Content-Type': 'application/json',
  });

  if (localStorage.getItem(ACCESS_TOKEN)) {
    headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
  }

  const defaults = {headers: headers};
  options = Object.assign({}, defaults, options);

  return fetch(options.url, options)
    .then(response => response.json().then(json => {
        if (!response.ok) {
          return Promise.reject(json);
        }
        return json;
      })
    );
};


const validateResponse = (response) => {
  if (!response.ok) {
    throw Error(response.statusText);
  }
  return response;
}

const requestBlob = (options) => {
  const headers = new Headers({});

  if (localStorage.getItem(ACCESS_TOKEN)) {
    headers.append('Authorization', 'Bearer ' + localStorage.getItem(ACCESS_TOKEN))
  }

  return fetch(options.url, options)
    .then(validateResponse)
    .then(response => response.blob())
    .then(blob => {
      return URL.createObjectURL(blob);
    });
};

export const getCurrentUser = () => {
  if (!localStorage.getItem(ACCESS_TOKEN)) {
    return Promise.reject("No access token set.");
  }
  if (!API_BASE_URL) {
    return Promise.reject("No API_BASE_URL set.");
  }

  return request({
    url: API_BASE_URL + "/api/user/me",
    method: 'GET'
  });
};


export const getEducationLevels = () => {
  return request({
    url: API_BASE_URL + "/api/education-levels",
    method: 'GET'
  });
}

export const getEducationForms = () => {
  return request({
    url: API_BASE_URL + "/api/education-forms",
    method: 'GET'
  });
}

export const getCategories = () => {
  return request({
    url: API_BASE_URL + "/api/categories",
    method: 'GET'
  });
}

export const getEducationYears = () => {
  return request({
    url: API_BASE_URL + "/api/education-years",
    method: 'GET'
  });
}

export const getEmploymentStabilities = () => {
  return request({
    url: API_BASE_URL + "/api/employment-stabilities",
    method: 'GET'
  });
}

export const getEmploymentTypes = () => {
  return request({
    url: API_BASE_URL + "/api/employment-types",
    method: 'GET'
  });
}

export const getFaculties = () => {
  return request({
    url: API_BASE_URL + "/api/faculties",
    method: 'GET'
  });
}

export const getSpecialitiesByFacultyId = (id) => {
  return request({
    url: API_BASE_URL + "/api/faculties/" + id + "/specialities",
    method: 'GET'
  });
}

export const getAllSpecialitiesByFacultyInIds = (ids) => {
  return request({
    url: API_BASE_URL + "/api/faculties/specialities",
    method: 'POST',
    body: JSON.stringify(ids)
  });
}

export const getSpecialities = () => {
  return request({
    url: API_BASE_URL + "/api/specialities",
    method: 'GET'
  });
}

export const getExperiences = () => {
  return request({
    url: API_BASE_URL + "/api/experiences",
    method: 'GET'
  });
}

export const login = (loginRequest) => {
  return request({
    url: API_BASE_URL + "/auth/login",
    method: 'POST',
    body: JSON.stringify(loginRequest)
  });
}

export const signup = (signupRequest) => {
  return request({
    url: API_BASE_URL + "/auth/signup",
    method: 'POST',
    body: JSON.stringify(signupRequest)
  });
}

export const EmployersApi = {
  search: (object, page = 0, size = 2) => {
    return request({
      url: API_BASE_URL + `/api/employers/search?page=${page}&size=${size}`,
      method: 'POST',
      body: JSON.stringify(object)
    });
  },
  getActiveOneByUserId: (id) => {
    return request({
      url: API_BASE_URL + "/api/employers/activeOne/" + id,
      method: 'GET'
    });
  },
  getAllByUser: (id) => {
    return request({
      url: API_BASE_URL + "/api/employers/user" + id,
      method: 'GET'
    });
  },
  getAllActiveByUser: (id) => {
    return request({
      url: API_BASE_URL + "/api/employers/active/" + id,
      method: 'GET'
    });
  },
  getOneById: (id) => {
    return request({
      url: API_BASE_URL + "/api/employers/" + id,
      method: 'GET'
    });
  },
  create: (object) => {
    return request({
      url: API_BASE_URL + "/api/employers",
      method: 'POST',
      body: JSON.stringify(object)
    });
  },
  getEmployersSummary: () => {
    return request({
      url: API_BASE_URL + "/api/employers/summary",
      method: 'GET'
    });
  },
  save: (id, object) => {
    return request({
      url: API_BASE_URL + "/api/employers/" + id,
      method: 'POST',
      body: JSON.stringify(object)
    });
  }
}

export const JobseekersApi = {
  getJobSeekersSummary: () => {
    return request({
      url: API_BASE_URL + "/api/jobseekers/summary",
      method: 'GET'
    });
  },
  search: (object, page = 0, size = 5) => {
    return request({
      url: API_BASE_URL + `/api/jobseekers/search?page=${page}&size=${size}`,
      method: 'POST',
      body: JSON.stringify(object)
    });
  },
  searchIds: (object) => {
    return request({
      url: API_BASE_URL + `/api/jobseekers/search-ids`,
      method: 'POST',
      body: JSON.stringify(object)
    });
  },
  getAllByUser: (id) => {
    return request({
      url: API_BASE_URL + "/api/jobseekers/user" + id,
      method: 'GET'
    });
  },
  getAllActiveByUser: (id) => {
    return request({
      url: API_BASE_URL + "/api/jobseekers/active/" + id,
      method: 'GET'
    });
  },
  getActiveOneByUserId: (id) => {
    return request({
      url: API_BASE_URL + "/api/jobseekers/activeOne/" + id,
      method: 'GET'
    });
  },
  getOneById: (id) => {
    return request({
      url: API_BASE_URL + "/api/jobseekers/" + id,
      method: 'GET'
    });
  },
  create: (object) => {
    return request({
      url: API_BASE_URL + "/api/jobseekers",
      method: 'POST',
      body: JSON.stringify(object)
    });
  },
  save: (id, object) => {
    return request({
      url: API_BASE_URL + "/api/jobseekers/" + id,
      method: 'POST',
      body: JSON.stringify(object)
    });
  }
};

export const VacanciesApi = {
  getAllByEmployer: (id, status, page = 0, size = 2) => {
    return request({
      url: API_BASE_URL + "/api/vacancies/employer/" + id + `?status=${status}&page=${page}&size=${size}`,
      method: 'GET'
    });
  },
  getSummary: (object = {}) => {
    return request({
      url: API_BASE_URL + "/api/vacancies/summary",
      method: 'POST',
      body: JSON.stringify(object)
    });
  },
  getGroupedPositions: () => {
    return request({
      url: API_BASE_URL + "/api/vacancies/positions",
      method: 'GET'
    });
  },
  getOneById: (id) => {
    return request({
      url: API_BASE_URL + "/api/vacancies/" + id,
      method: 'GET'
    });
  },
  create: (object) => {
    return request({
      url: API_BASE_URL + "/api/vacancies",
      method: 'POST',
      body: JSON.stringify(object)
    });
  },
  searchOwn: (object, page = 0, size = 6) => {
    return request({
      url: API_BASE_URL + `/api/vacancies/search/own?page=${page}&size=${size}`,
      method: 'POST',
      body: JSON.stringify(object)
    });
  },
  search: (object, page = 0, size = 6) => {
    return request({
      url: API_BASE_URL + `/api/vacancies/search?page=${page}&size=${size}`,
      method: 'POST',
      body: JSON.stringify(object)
    });
  },
  searchIds: (object) => {
    return request({
      url: API_BASE_URL + `/api/vacancies/search-ids`,
      method: 'POST',
      body: JSON.stringify(object)
    });
  },
  searchOwnIds: (object) => {
    return request({
      url: API_BASE_URL + `/api/vacancies/search-ids/own`,
      method: 'POST',
      body: JSON.stringify(object)
    });
  },
  save: (id, object) => {
    return request({
      url: API_BASE_URL + "/api/vacancies/" + id,
      method: 'POST',
      body: JSON.stringify(object)
    });
  },
  update: (id, object) => {
    return request({
      url: API_BASE_URL + "/api/vacancies/update/" + id,
      method: 'POST',
      body: JSON.stringify(object)
    });
  }
}

export const MeetingsApi = {
  getOneById: (id) => {
    return request({
      url: API_BASE_URL + "/api/meetings/" + id,
      method: 'GET'
    });
  },
  search: (object, page = 0, size = 10) => {
    return request({
      url: API_BASE_URL + `/api/meetings/search?page=${page}&size=${size}`,
      method: 'POST',
      body: JSON.stringify(object)
    });
  },
  create: (object) => {
    return request({
      url: API_BASE_URL + "/api/meetings",
      method: 'POST',
      body: JSON.stringify(object)
    });
  },
  save: (id, object) => {
    return request({
      url: API_BASE_URL + "/api/meetings/" + id,
      method: 'POST',
      body: JSON.stringify(object)
    });
  }
}

export const UsersApi = {
  getOneById: (id) => {
    return request({
      url: API_BASE_URL + "/api/users/" + id,
      method: 'GET'
    });
  },
  search: (object, page = 0, size = 10) => {
    return request({
      url: API_BASE_URL + `/api/users/search?page=${page}&size=${size}`,
      method: 'POST',
      body: JSON.stringify(object)
    });
  },
  save: (id, object) => {
    return request({
      url: API_BASE_URL + "/api/users/" + id,
      method: 'POST',
      body: JSON.stringify(object)
    });
  }
};

export const UploadApi = {
  upload: (formData) => {
    return request({
      url: API_BASE_URL + "/api/uploadFile",
      method: 'POST',
      headers: undefined,
      body: formData
    });
  },
  download: (url) => {
    return requestBlob({
      url: API_BASE_URL + "/api/downloadFile/" + url,
      method: 'GET'
    });
  }
};
