import React from 'react';
import Button from "../UI/Button/Button";
import './notFound.css';
import {GO_BACK, NOT_FOUND_PAGE} from "../../constants/common/notification";

const NotFound = () =>  {

    return (
      <div className="form-container">
        <div className="form-content page-not-found">
          <h1 className="title">
            404
          </h1>
          <div className="desc">
            {NOT_FOUND_PAGE}
          </div>
          <Button to="/" className='go-back-btn' label={GO_BACK}/>
        </div>
      </div>
    );
}

export default NotFound;