import { notification } from 'antd';
import * as actionTypes from "./actionTypes";
import {MeetingsApi} from "../../util/APIUtils";
import {SUCCESS_SAVED} from "../../constants/common/notification";
import meetingPath from "../../constants/path/meeting";
import {onCatch} from "../../util/helpers";

const fetchMeetingsStart = () => {
  return {
    type: actionTypes.FETCH_MEETINGS_START
  };
};

// const fetchMeetingsSuccess = (list) => {
//   return {
//     type: actionTypes.FETCH_MEETINGS_SUCCESS,
//     list
//   };
// };

const fetchOneMeetingSuccess = (object) => {
  return {
    type: actionTypes.FETCH_ONE_MEETINGS_SUCCESS,
    object
  };
};

const fetchMeetingsFail = (error) => {
  return {
    type: actionTypes.FETCH_MEETINGS_FAIL,
    error
  };
};

const createMeetingsStart = () => {
  return {
    type: actionTypes.CREATE_MEETINGS_START
  };
};

const createMeetingSuccess = (pathRedirect) => {
  return {
    type: actionTypes.CREATE_MEETINGS_SUCCESS,
    pathRedirect
  };
};

const saveMeetingSuccess = (pathRedirect) => {
  return {
    type: actionTypes.SAVE_MEETINGS_SUCCESS,
    pathRedirect
  };
};

const createMeetingFail = (error) => {
  return {
    type: actionTypes.CREATE_MEETINGS_FAIL,
    error
  };
};


export const resetMeetings = () => {
  return {
    type: actionTypes.RESET_MEETINGS
  };
};

const searchMeetingStart = () => {
  return {
    type: actionTypes.SEARCH_MEETINGS_START
  };
};

const searchMeetingSuccess = (list) => {
  return {
    type: actionTypes.SEARCH_MEETINGS_SUCCESS,
    list
  };
};

const searchMeetingFail = (error) => {
  return {
    type: actionTypes.SEARCH_MEETINGS_FAIL,
    error
  };
};

export const getOneMeetingById = (id) => {
  return dispatch => {
    dispatch(fetchMeetingsStart());
    MeetingsApi.getOneById(id)
      .then(response => {
        dispatch(fetchOneMeetingSuccess(response));
      })
      .catch(error => {
        const m = onCatch(error);
        dispatch(fetchMeetingsFail(m));
      });
  };
};

export const createMeeting = (object) => {
  return dispatch => {
    dispatch(createMeetingsStart());
    MeetingsApi.create(object)
      .then(response => {
        const m = (response && response.response) || '';
        notification.success({
          message: SUCCESS_SAVED,
          description: m
        });
        dispatch(createMeetingSuccess(object.pathRedirect || meetingPath.path.list));
      })
      .catch(error => {
        const m = onCatch(error);
        dispatch(createMeetingFail(m));
      });
  };
};

export const saveMeeting = (id, object) => {
  return dispatch => {
    dispatch(createMeetingsStart());
    MeetingsApi.save(id, object)
      .then(response => {
        const m = (response && response.response) || '';
        notification.success({
          message: SUCCESS_SAVED,
          description: m
        });
        dispatch(saveMeetingSuccess(object.pathRedirect || '/meetings/feature'));
      })
      .catch(error => {
        const m = onCatch(error);
        dispatch(createMeetingFail(m));
      });
  };
};

export const setMeetingFilter = filter => ({
  type: 'SET_MEETING_FILTER',
  filter
});

export const searchMeetings = (filter, page = 0, size) => {
  return dispatch => {
    dispatch(searchMeetingStart());
    MeetingsApi.search(filter, page, size)
      .then(response => {
        dispatch(searchMeetingSuccess(response));
      })
      .catch(error => {
        const m = onCatch(error);
        dispatch(searchMeetingFail(m));
      });
  };
};
