import React, { Component } from 'react';
import {TITLE, BRAND} from "../../constants/common/common";
import main from '../../assets/images/main.png';
import './Home.css';

class Home extends Component {
    render() {
        return (
            <div className="home-container">
                <div className="container">
                    <img src={main} className='main-logo' alt={BRAND}/>
                    <h1 className="home-title">{TITLE}</h1>
                </div>
            </div>
        )
    }
}

export default Home;