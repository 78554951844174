import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link, Redirect} from 'react-router-dom'

import {LOGIN, NOT_SIGNED_UP, OR, SIGN_UP} from "../../../constants/common/auth";
import SocialLogin from "../../../components/User/Login/SocialLogin";
import {LoginForm} from "../../../components/User/Login";
import * as actions from "../../../store/actions/auth";
import './Login.css';

class Login extends Component {
  state = {
    email: '',
    password: ''
  };

  componentDidMount() {
    // If the OAuth2 login encounters an error, the user is redirected to the /login page with an error.
    // Here we display the error and then remove the error query parameter from the location.
    if (this.props.location.state && this.props.location.state.error) {
      // setTimeout(() => {
      //   notification.error({
      //     message: 'Error',
      //     description: this.props.location.state.error
      //   });
      //   this.props.history.push('/');
      // }, 100);
      this.props.history.push('/');
    }
  }

  handleInputChange = (event) => {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName]: inputValue
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.props.onAuth({...this.state});
  };

  render() {
    const {handleSubmit, handleInputChange} = this;
    const {email, password} = this.state;
    if (this.props.isAuthenticated) {
      return <Redirect
        to={{
          pathname: '/',
          state: {from: this.props.location}
        }}/>;
    }

    return (
      <div className="form-container">
        <div className="form-content form-login">
          <h1 className="form-title">{LOGIN}</h1>
          <SocialLogin/>
          <div className="or-separator">
            <span className="or-text">{OR}</span>
          </div>
          <LoginForm {...{handleSubmit, handleInputChange, email, password}} />
          <div className='mt15'>
            <span className="signup-link">{NOT_SIGNED_UP}&nbsp;<Link to="/signup">{SIGN_UP}!</Link></span>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    me: state.auth.me,
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthenticated: state.auth.token !== null,
    authRedirectPath: state.auth.authRedirectPath
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAuth: (loginRequest) => dispatch(actions.auth(loginRequest)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
