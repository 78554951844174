import * as actionTypes from './actionTypes';
import {UploadApi} from "../../util/APIUtils";
import {onCatch} from "../../util/helpers";

const uploadStart = () => {
  return {
    type: actionTypes.UPLOAD_FILE_START
  };
};

const uploadSuccess = (object) => {
  return {
    type: actionTypes.UPLOAD_FILE_SUCCESS,
    object
  };
};

const downloadedSuccess = (data, imageUrl) => {
  return {
    type: actionTypes.DOWNLOADED_FILE_SUCCESS,
    file: {imageUrl, data}
  };
};

const uploadFail = (error) => {
  return {
    type: actionTypes.UPLOAD_FILE_FAIL,
    error
  };
};

export const resetUploadFile = () => {
  return {
    type: actionTypes.UPLOAD_FILE_RESET
  };
};

export const uploadFile = (data) => {
  return dispatch => {
    dispatch(uploadStart());
    UploadApi.upload(data)
      .then(response => {
        if(response && response.fileDownloadUri) {
          dispatch(uploadSuccess(response))
        } else {
          const error = (response && response.message) || "Some Error Occurred";
          dispatch(uploadFail(error));
        }
      }).catch(error => {
        const m = onCatch(error);
        dispatch(uploadFail(m));
      });
  };
};

export const downloadFile = (imageUrl) => {
  return dispatch => {
    dispatch(uploadStart());
    UploadApi.download(imageUrl)
      .then(response => {
        if(response) {
          dispatch(downloadedSuccess(response, imageUrl))
        } else {
          const error = (response && response.message) || "Some Error Occurred";
          dispatch(uploadFail(error));
        }
      }).catch(error => {
        const m = onCatch(error);
        dispatch(uploadFail(m));
      });
  };
};
