import React from 'react';
import {Link} from 'react-router-dom';
import {Icon} from "antd";

const Button = ({
                  id,
                  icon = '',
                  className = '',
                  style = {},
                  to = false,
                  type = 'primary',
                  label = '',
                  children,
                  onClick,
                  disabled = false,
                  size = false,
                  target = '',
                  actionType = ''
                }) => {
  const others = {};
  others.disabled = disabled;
  others.target = target;
  others.type = actionType;

  return (
    !to ? (
      <button
        id={id} style={style} onClick={onClick} {...others}
        className={`ant-btn ant-btn-${type || 'primary'} ant-btn-${size || ''} ${disabled ? 'disabled' : ''} ${className}`}
      >
        <span>
          {icon ? <Icon type={`${icon}`} /> : ''}
          {icon && label ? <span>&nbsp;</span> : null}
          {label ? (<span>{label}</span>) : ''}
          {children ? (<span>{children}</span>) : ''}
        </span>
      </button>
    ) : (
      <Link
        className={`ant-btn ant-btn-${type || 'primary'} ant-btn-${size || ''} ${className}`}
        to={to} style={style} id={id} {...others}
      >
        {icon ? <Icon type={`${icon}`} /> : ''}
        {icon && label ? <span>&nbsp;</span> : null}
        {label ? (<span>{label}</span>) : ''}
        {children ? (<span>{children}</span>) : ''}
      </Link>
    )
  );
};

export default Button;
