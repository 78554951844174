import {isArray, isEmptyArray, isString} from "./variableTypes";

export const updateObject = (oldObject, updatedProperties) => {
  return {
    ...oldObject,
    ...updatedProperties
  };
};

export const checkValidity = (value, rules) => {
  let isValid = true;
  if (!rules) {
    return true;
  }

  if (rules.required && isString(value)) {
    isValid = value.trim() !== '' && isValid;
  }
  if (rules.required && isArray(value)) {
    isValid = !isEmptyArray(value) && isValid;
  }
  if (rules.minLength) {
    isValid = value.length >= rules.minLength && isValid
  }

  if (rules.maxLength) {
    isValid = value.length <= rules.maxLength && isValid
  }

  if (rules.isEmail && value) {
    const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
    isValid = pattern.test(value) && isValid
  }

  // if (rules.isPhone) {
  //   const pattern = /^\+(?:[0-9] ?){6,14}[0-9]$/;
  //   isValid = pattern.test(value) && isValid
  // }

  if (rules.isTrue) {
    isValid = value && isValid
  }

  if (rules.isNumeric) {
    const pattern = /^\d+$/;
    isValid = pattern.test(value) && isValid
  }

  if (rules.isNumeric && rules.min) {
    isValid = parseFloat(value) >= parseFloat(rules.min) && isValid
  }

  if (rules.isNumeric && rules.max) {
    isValid = parseFloat(value) <= parseFloat(rules.max) && isValid
  }
  return isValid;
}

export const compareValues = (key, order = 'asc') => {
  return (a, b) => {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      return 0;
    }

    const varA = (typeof a[key] === 'string')
      ? a[key].toUpperCase() : a[key];
    const varB = (typeof b[key] === 'string')
      ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return (
      (order === 'desc') ? (comparison * -1) : comparison
    );
  }
}

export const objectFlip = (obj) =>{
  const ret = {};
  Object.keys(obj).forEach(key => {
    ret[obj[key]] = key;
  });
  return ret;
}