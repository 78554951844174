import React from 'react';
import Button from "../../UI/Button/Button";
import {LOGIN} from "../../../constants/common/auth";

const LoginForm = ({handleSubmit, handleInputChange, email, password}) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className="form-item">
        <input type="email" name="email"
               className="form-control" placeholder="Email"
               value={email} onChange={handleInputChange} required/>
      </div>
      <div className="form-item">
        <input type="password" name="password"
               className="form-control" placeholder="Password"
               value={password} onChange={handleInputChange} required/>
      </div>
      <div className="form-item">
        <Button actionType="submit" size='lg' className="btn-block">{LOGIN}</Button>
      </div>
    </form>
  );
};

export default LoginForm;
