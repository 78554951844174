import asyncComponent from "./hoc/asyncComponents/asyncComponents";

export const asyncProfilesSearch = asyncComponent(() => {
  return import('./containers/User/User/UserSearch');
});

export const asyncProfile = asyncComponent(() => {
  return import('./containers/User/Profile/Profile');
});

export const asyncSignup = asyncComponent(() => {
  return import('./containers/User/Signup/Signup');
});

export const asyncUserEdit = asyncComponent(() => {
  return import('./containers/User/User/UserEdit');
});

// JOBSEEKER

export const asyncJobSeekerSearch = asyncComponent(() => {
  return import('./containers/JobSeeker/JobSeekerSearch');
});

export const asyncJobSeekerCard = asyncComponent(() => {
  return import('./containers/JobSeeker/JobSeekerCardContainer');
});

export const asyncJobSeekerCreate = asyncComponent(() => {
  return import('./containers/JobSeeker/JobSeekerCreate');
});

export const asyncJobSeekerEdit = asyncComponent(() => {
  return import('./containers/JobSeeker/JobSeekerEdit');
});

// EMPLOYER

export const asyncEmployerCreate = asyncComponent(() => {
  return import('./containers/Employer/EmployerCreate');
});

export const asyncEmployerEdit = asyncComponent(() => {
  return import('./containers/Employer/EmployerEdit');
});

export const asyncEmployerSearch = asyncComponent(() => {
  return import('./containers/Employer/EmployerSearch');
});

// VACANCY

export const asyncVacancy = asyncComponent(() => {
  return import('./containers/Vacancy/VacancyCardContainer');
});

export const asyncVacancyCreate = asyncComponent(() => {
  return import('./containers/Vacancy/VacancyCreate');
});

export const asyncVacancyList = asyncComponent(() => {
  return import('./containers/Vacancy/VacancyList');
});

export const asyncVacancyEdit = asyncComponent(() => {
  return import('./containers/Vacancy/VacancyEdit');
});

export const asyncVacancySearch = asyncComponent(() => {
  return import('./containers/Vacancy/VacancySearch');
});

// MEETING

export const asyncMeetingCreate = asyncComponent(() => {
  return import('./containers/Meeting/MeetingCreate');
});

export const asyncMeetingEdit = asyncComponent(() => {
  return import('./containers/Meeting/MeetingEdit');
});

export const asyncMeetingView = asyncComponent(() => {
  return import('./containers/Meeting/MeetingView');
});

export const asyncMeetingList = asyncComponent(() => {
  return import('./containers/Meeting/MeetingList');
});
