import {SELECT_OPTION} from "./notification";

export const NO_EMPLOYER_PROFILES = 'Схоже, ви ще не заповнили профіль компанії';
export const PROFILE = 'Профіль';
export const PROFILES = 'Користувачі';
export const SEARCH_ALL_PROFILES = 'Пошук по всіх користувачах';
export const NEW_PROFILE = 'Новий профіль';
export const EMPTY_PROFILE = 'Профіль не заповнено';
export const FINISH = 'Готово!';
export const COMPLETED_PROFILE = 'Профіль готовий до роботи';
export const CREATE_PROFILE = 'Створити профіль';
export const POLL = 'Анкетування';
export const START_POLL = 'Будь ласка, пройдіть анкетування'
export const START_PROFILE = 'Будь ласка, заповніть профіль'

export const ALLOWED_ROLES = ['ADMIN', 'JOBSEEKER', 'EMPLOYER']
export const ALLOWED_ROLES_LIST = {
  ADMIN: 'ADMIN',
  JOBSEEKER: 'JOBSEEKER',
  EMPLOYER: 'EMPLOYER'
}

export const FIRST_NAME = 'Ім\'я'
export const LAST_NAME = 'Прізвище'
export const MIDDLE_NAME = 'По-батькові'
export const FULL_NAME = 'ПІБ'
export const DESCRIPTIONS = 'Опис'
export const PASSWORD = 'Пароль'
export const BUSINESS_NAME = 'Назва компанії'
export const COMPANY_LOGO = 'Логотип компанії'
export const PHOTO = 'Фото'
export const SHORT_BUSINESS_NAME = 'Компанія'
export const FEMALE = 'Жіноча';
export const MALE = 'Чоловіча';
export const GENDER = 'Стать';
export const AGE = 'Вік';
export const AGE_SUFFIX = 'р.';
export const DATE_OF_BIRTH = 'Дата народження';
export const PRIVATE_EMAIL = 'Додаткова електронна адреса';
export const COMPANY_EMAIL = 'Електронна адреса компанії';
export const EMAIL = 'Електронна адреса';
export const PHONE_NUMBERS = 'Номер телефону';
export const SOCIAL_LINKS = 'Соціальні мережі';
export const EDUCATION_LEVEL = 'Статус';
export const EDUCATION_YEAR = 'Рік навчання';
export const EDUCATION_FORM = 'Форма навчання';
export const GRADUATION_YEAR = 'Рік випуску';
export const HIGHLIGHT = 'Найяскравіший момент з університетського життя';
export const SELECTED_LOCATION = 'Країна/область/населений пункт, який обрали для життя та кар\'єри';
export const ADVICE_DESCRIPTION = 'Поради та побажання наступним поколінням студентів';
export const FACULTY = 'Факультет';
export const FACULTY_EXTRA_NAME = 'Назва факультету';
export const SPECIALITY = 'Спеціальність';
export const SPECIALITY_EXTRA_NAME = 'Назва спеціальністі';
export const PERSONAL_QUALITIES = 'Особисті якості';
export const ADDITIONAL_EDUCATION = 'Додаткова освіта';
export const PERSONAL_ACHIEVEMENTS = 'Ваші досягнення';
export const CONTINUE_EDUCATION = 'Я планую продовжувати освіту протягом життя';
export const LANGUAGES = 'Іноземні мови';
export const IS_EMPLOYED = 'Я працевлаштований на даний момент';
export const IS_SEARCHING_JOB = 'Я шукаю роботу';
export const IS_AGREED_TO_TERMS_CONDITIONS = 'Заповнивши цю анкету, ти даєш згоду на обробку персональних данних';
export const EMPLOYMENT_SECTOR = 'Сектор, в якому працюєте';
export const IS_REQUIRED_DIPLOMA = 'Моя посада вимагає диплома про вищу освіту';
export const CATEGORIES = 'Сфера діяльності';
export const EXPERIENCE = 'Досвід роботи';
export const EXPERIENCE_ACHIEVEMENTS = 'Обов\'язки, навички, компетенції, які здобули в професійній дяльності';
export const EMPLOYMENT_TYPE = 'Режим роботи (зайнятість)';
export const EMPLOYMENT_STABILITY = 'Стабільність трудової діяльності';
export const PERSONAL_PREFERENCES = 'В якій компанії/установі хотіли б працювати';
export const PRIVATE_EMPLOYMENT_SECTOR = 'Приватний';
export const SOCIAL_EMPLOYMENT_SECTOR = 'Громадський';
export const GOVERNMENT_EMPLOYMENT_SECTOR = 'Державний';
export const RESPONSIBILITY_DESCRIPTIONS = 'Обов\'язки';
export const PLACE_OF_WORK = 'Вкажи місце роботи';
export const CURRENT_POSITION = 'Посада';
export const SKILL_DESCRIPTIONS = 'Навички';

export const ALLOWED_GENDERS = [
  {
    label: SELECT_OPTION,
    value: ''
  },
  {
    label: FEMALE,
    value: 'FEMALE'
  },
  {
    label: MALE,
    value: 'MALE'
  }
]

export const ALLOWED_EMPLOYMENT_SECTOR = [
  {
    label: SELECT_OPTION,
    value: ''
  },
  {
    label: PRIVATE_EMPLOYMENT_SECTOR,
    value: 'PRIVATE'
  },
  {
    label: SOCIAL_EMPLOYMENT_SECTOR,
    value: 'SOCIAL'
  },
  {
    label: GOVERNMENT_EMPLOYMENT_SECTOR,
    value: 'GOVERNMENT'
  }
]

export const CONTACT_USER = 'Контактна особа';
export const CONTACT_INFO = 'Контактна інформація';
export const EDU_INFO = 'Освіта';
export const ADDITIONAL_INFO = 'Додаткова інформація';
export const EMPLOYMENT = 'Працевлаштування';

export const ACTIVE = 'Активно';
export const INACTIVE = 'Не активно';
export const DELETED = 'Видалено';
export const LOCKED = 'Заблоковано';

export const STATUS_LIST = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  DELETED: 'DELETED',
  LOCKED: 'LOCKED'
};

export const STATUS_LIST_ICON = {
  ACTIVE: 'check-circle',
  INACTIVE: 'close-circle',
  DELETED: 'trash',
  LOCKED: 'lock'
};

export const STATUS_LIST_COLOR = {
  ACTIVE: '#52c41a',
  INACTIVE: '#ccc',
  DELETED: '#ccc',
  LOCKED: '#ccc'
};

export const STATUS_LIST_VALUES = {
  ACTIVE,
  INACTIVE,
  DELETED,
  LOCKED
};
