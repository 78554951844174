const getRoot = () => (typeof window !== 'undefined')
    ? window.location.protocol + '//' + window.location.host
    : ''
const getHostname = () => (typeof window !== 'undefined')
    ? window.location.protocol + '//' + window.location.hostname
    : ''
export const REACT_APP_BASE_URL = getRoot() || '';
export const API_BASE_URL = getHostname(); //+ ':' + process.env.REACT_APP_API_PORT || '8080';

export const ACCESS_TOKEN = 'accessToken';

export const OAUTH2_REDIRECT_URI = `${REACT_APP_BASE_URL}/oauth2/redirect`
export const AZURE_AUTH_URL = API_BASE_URL + '/oauth2/authorize/azure?redirect_uri=' + OAUTH2_REDIRECT_URI;

export const SING_IN_ADD = "Увійти за допомогою облікового запису";
export const SUCCESS_SING_UP = "Ви зареєструвалися. Будь ласка, виконайте вхід";
export const SIGNED_UP = 'Я вже зареєстрованний';
export const NOT_SIGNED_UP = 'Я ще не зареєстрованний';
export const SIGN_UP = 'Реєстрація';
export const LOGIN = 'Вхід';
export const LOGOUT = 'Вихід';
export const OR = 'АБО';
export const SIGN_AS_JOBSEEKER = 'Я з ДонНУ';
export const SIGN_AS_EMPLOYER = 'Партнер ДонНУ';
export const AUTH = 'Авторизація';
