import {NEW_PROFILE, PROFILE, PROFILES} from '../common/profile';
import {EDITING, USER} from '../common/notification';
import {HOME} from '../common/common';

const path = {
  credential: "/profile/credential/:id",
  create: "/profile/create",
  edit: "/profile/edit/:id",
  view: "/profile",
  list: "/profiles"
};

const items = {
  create: {
    title: NEW_PROFILE,
    path: path.create,
    allowedRoles: ['JOBSEEKER', 'EMPLOYER'],
    breadcrumb: [
      {to: '/', label: HOME},
      {to: path.view, label: PROFILE},
      {to: path.create, label: NEW_PROFILE}
    ]
  },
  edit: {
    title: EDITING,
    path: path.edit,
    allowedRoles: ['JOBSEEKER', 'EMPLOYER'],
    breadcrumb: [
      {to: '/', label: HOME},
      {to: path.view, label: PROFILE},
      {to: path.create, label: EDITING}
    ]
  },
  credential: {
    title: USER,
    path: path.credential,
    allowedRoles: ['ADMIN'],
    breadcrumb: [
      {to: '/', label: HOME},
      {to: path.view, label: PROFILE},
      {to: path.create, label: USER}
    ]
  },
  view: {
    title: PROFILE,
    path: path.view,
    allowedRoles: ['JOBSEEKER', 'EMPLOYER'],
    breadcrumb: [
      {to: '/', label: HOME},
      {to: path.view, label: PROFILE}
    ]
  },
  list: {
    title: PROFILES,
    path: path.list,
    allowedRoles: ['ADMIN']
  }
};

export default {path, items}
