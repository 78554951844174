import profilePath from "./profile";
import {EDITING} from "../common/notification";
import {EMPLOYERS, HOME} from "../common/common";
import {NEW_PROFILE, PROFILE} from "../common/profile";

const path = {
  create: "/employers/create",
  edit: "/employers/edit/:id",
  view: "/employers/:id",
  list: "/employers",
}

const items = {
  create: {
    title: NEW_PROFILE,
    path: path.create,
    allowedRoles: ['ADMIN', 'EMPLOYER'],
    breadcrumb: [
      {to: '/', label: HOME},
      {to: path.list, label: PROFILE},
      {to: path.create, label: NEW_PROFILE}
    ]
  },
  edit: {
    title: EDITING,
    path: path.edit,
    allowedRoles: ['ADMIN', 'EMPLOYER'],
    breadcrumb: [
      {to: '/', label: HOME},
      {to: path.list, label: PROFILE},
      {to: path.edit, label: EDITING}
    ]
  },
  view: {
    title: PROFILE,
    path: path.view,
    allowedRoles: ['ADMIN', 'EMPLOYER'],
    // breadcrumb: [
    //   {to: '/', label: HOME},
    //   {to: path.list, label: PROFILE}
    // ]
  },
  list: {
    title: EMPLOYERS,
    path: path.list,
    redirect: profilePath.path.view,
    allowedRoles: ['ADMIN'],
    // breadcrumb: [
    //   {to: '/', label: HOME},
    //   {to: path.list, label: EMPLOYERS}
    // ]
  },
};

export default {path, items}
