import React from 'react';
import {CSVLink} from 'react-csv';
import {PageHeader, Tag, Icon} from 'antd';
import {Route, Link, Redirect} from "react-router-dom";

import {isArray, isEmptyArray} from "../../util/variableTypes";
import Breadcrumb from "../UI/Breadcrumb/Breadcrumb";
import {getRole} from "../../util/userHelpers";
import Button from "../UI/Button/Button";
import Aux from "../../hoc/Aux";

const PrivateRoute = ({
                        component: Component, me, allowedRoles = [], authenticated,
                        title = '', subTitle = '', tags = [], actions = [], pathRedirect, allowedPath = [],
                        breadcrumb = [], redirect = '/login', computedMatch, ...rest
}) => {
  const {params: {id = ''} = {} } = computedMatch || {};
  const role = getRole(me);
  if (!role && isArray(allowedRoles) && !isEmptyArray(allowedRoles)) {
      return (
          <Route
              {...rest}
              render={props => (
                  <Redirect
                      to={{
                          pathname: '/',
                          state: {from: props.location}
                      }}
                  />
              )}
          />
      );
  }

  const validRole = !(role && isArray(allowedRoles) && !isEmptyArray(allowedRoles))
    ? true : allowedRoles.includes(role);

  if (pathRedirect && ![...allowedPath, pathRedirect].includes(rest.path)) {
      return (
          <Route
              {...rest}
              render={props => (
                  <Redirect
                      to={{
                          pathname: pathRedirect,
                          state: {from: props.location}
                      }}
                  />
              )}
          />
      );
  }

  const pageTags = isArray(tags) && !isEmptyArray(tags)
    ? tags.map((tag, i) => <Tag key={i} color={tag.color || 'blue'}>{tag.text}</Tag>)
    : [];

  const pageActions = isArray(actions) && !isEmptyArray(actions)
    ? actions.map((btn, i) => {
      return btn.type === 'csv'
        ? (<CSVLink key={i} {...{...btn}}>
            <b>
              <Icon style={{ fontSize: '16px', paddingTop: '5px' }} type='download'/>
              &nbsp;EXPORT CSV
            </b>
          </CSVLink>)
        : (<Button key={i} {...{...btn, type: btn.to ? 'link' : btn.type}} />)
    })
    : [];
  return (
    <Route
      {...rest}
      render={props =>
        authenticated && validRole ? (
          <Aux>
            <Breadcrumb>
              {breadcrumb.map(({to, label}) => {
                const link = id ? to.replace(':id', id) : to;
                return (
                  <Link key={link} to={link}>
                    {label}
                  </Link>
                )
              })}
            </Breadcrumb>
            <PageHeader
              title={title}
              className="site-page-header"
              subTitle={subTitle}
              tags={pageTags}
              extra={pageActions}
            >
              <Component {...rest} {...props} />
            </PageHeader>
          </Aux>
        ) : (
          <Redirect
            to={{
              pathname: redirect.replace(':id', id),
              state: {from: props.location}
            }}
          />
        )
      }
    />
  );
};;

export default PrivateRoute