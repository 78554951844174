import * as actionTypes from './actionTypes';
import { notification } from 'antd';

import { VacanciesApi } from "../../util/APIUtils";
import {SUCCESS_SAVED} from "../../constants/common/notification";
import {isEmptyObject, isObject} from "../../util/variableTypes";
import {onCatch} from "../../util/helpers";

const fetchVacanciesStart = () => {
  return {
    type: actionTypes.FETCH_VACANCIES_START
  };
};

export const fetchVacanciesSuccess = (list) => {
  return {
    type: actionTypes.FETCH_VACANCIES_SUCCESS,
    list
  };
};

const fetchOneVacancySuccess = (object) => {
  return {
    type: actionTypes.FETCH_ONE_VACANCIES_SUCCESS,
    object
  };
};

const fetchVacanciesFail = (error) => {
  return {
    type: actionTypes.FETCH_VACANCIES_FAIL,
    error
  };
};

const createVacancyStart = () => {
  return {
    type: actionTypes.CREATE_VACANCIES_START
  };
};

const createVacancySuccess = (pathRedirect) => {
  return {
    type: actionTypes.CREATE_VACANCIES_SUCCESS,
    pathRedirect
  };
};

const saveVacancySuccess = (pathRedirect) => {
  return {
    type: actionTypes.SAVE_VACANCIES_SUCCESS,
    pathRedirect
  };
};

const createVacancyFail = (error) => {
  return {
    type: actionTypes.CREATE_VACANCIES_FAIL,
    error
  };
};

export const resetVacancies = () => {
  return {
    type: actionTypes.RESET_VACANCIES
  };
};

const fetchPositionsStart = () => {
  return {
    type: actionTypes.FETCH_POSITIONS_START
  };
};

const fetchPositionsSuccess = (list) => {
  return {
    type: actionTypes.FETCH_POSITIONS_SUCCESS,
    list
  };
};

const fetchPositionsFail = (error) => {
  return {
    type: actionTypes.FETCH_POSITIONS_FAIL,
    error
  };
};

const searchVacanciesStart = () => {
  return {
    type: actionTypes.SEARCH_VACANCIES_START
  };
};

const searchVacanciesSuccess = (list) => {
  return {
    type: actionTypes.SEARCH_VACANCIES_SUCCESS,
    list
  };
};

const searchVacanciesFail = (error) => {
  return {
    type: actionTypes.SEARCH_VACANCIES_FAIL,
    error
  };
};

export const loadVacanciesByEmployerId = (id, page = 0, size, status = 'Active') => {
  return dispatch => {
    dispatch(fetchVacanciesStart());
    VacanciesApi.getAllByEmployer(id, status, page, size)
      .then(response => {
        dispatch(fetchVacanciesSuccess(response));
      })
      .catch(error => {
        const m = onCatch(error);
        dispatch(fetchVacanciesFail(m));
      });
  };
};

export const getVacanciesSummary = (filter) => {
  return dispatch => {
    dispatch(fetchVacanciesStart());
    VacanciesApi.getSummary(filter)
      .then(response => {
        dispatch(fetchVacanciesSuccess(response));
      })
      .catch(error => {
        const m = onCatch(error);
        dispatch(fetchVacanciesFail(m));
      });
  };
};

export const searchVacancies = (filter, page = 0, size) => {
  return dispatch => {
    if (isObject(filter) && !isEmptyObject(filter)) {
      dispatch(searchVacanciesStart());
      const f = { ...filter }
      if (filter.type) {
        f.status = filter.type
      }
      const fn = filter.isOwn ? VacanciesApi.searchOwn : VacanciesApi.search;
      fn(f, page, size)
        .then(response => {
          dispatch(searchVacanciesSuccess(response));
        })
        .catch(error => {
          const m = onCatch(error);
          dispatch(searchVacanciesFail(m));
        });
    };
    }
};


export const searchVacanciesIds = (filter) => {
  return dispatch => {
    if (isObject(filter) && !isEmptyObject(filter)) {
      dispatch(searchVacanciesStart());
      const f = { ...filter }
      if (filter.type) {
        f.status = filter.type
      }
      const fn = filter.isOwn ? VacanciesApi.searchOwnIds : VacanciesApi.searchIds;
      fn(f)
        .then(response => {
          dispatch(searchVacanciesSuccess(response));
        })
        .catch(error => {
          const m = onCatch(error);
          dispatch(searchVacanciesFail(m));
        });
    };
    }
};


export const getGroupedPositions = () => {
  return dispatch => {
    dispatch(fetchPositionsStart());
    VacanciesApi.getGroupedPositions()
      .then(response => {
        dispatch(fetchPositionsSuccess(response));
      })
      .catch(error => {
        const m = onCatch(error);
        dispatch(fetchPositionsFail(m));
      });
  };
};

export const getOneVacancyById = (id) => {
  return dispatch => {
    dispatch(fetchVacanciesStart());
    VacanciesApi.getOneById(id)
      .then(response => {
        dispatch(fetchOneVacancySuccess(response));
      })
      .catch(error => {
        const m = onCatch(error);
        dispatch(fetchVacanciesFail(m));
      });
  };
};

export const createVacancy = (object) => {
  return dispatch => {
    dispatch(createVacancyStart());
    VacanciesApi.create(object)
      .then(response => {
        const m = (response && response.response) || '';
        notification.success({
          message: SUCCESS_SAVED,
          description: m
        });
        dispatch(createVacancySuccess('/vacancies'));
      })
      .catch(error => {
        const m = onCatch(error);
        dispatch(createVacancyFail(m));
      });
  };
};

export const saveVacancy = (id, object) => {
  return dispatch => {
    dispatch(createVacancyStart());
    VacanciesApi.save(id, object)
      .then(response => {
        const m = (response && response.response) || '';
        notification.success({
          message: SUCCESS_SAVED,
          description: m
        });
        dispatch(saveVacancySuccess('/vacancies'));
      })
      .catch(error => {
        const m = onCatch(error);
        dispatch(createVacancyFail(m));
      });
  };
};

export const updateStatusVacancy = (id, object) => {
  return dispatch => {
    dispatch(createVacancyStart());
    VacanciesApi.update(id, object)
      .then(response => {
        const m = (response && response.response) || '';
        notification.success({
          message: SUCCESS_SAVED,
          description: m
        });
        dispatch(saveVacancySuccess('/vacancies'));
      })
      .catch(error => {
        const m = onCatch(error);
        dispatch(createVacancyFail(m));
      });
  };
};

export const setVacancyFilter = filter => ({
  type: 'SET_VACANCY_FILTER',
  filter
});
