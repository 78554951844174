import React from 'react';
import './breadcrumb.css'

const Separator = ({ children, ...props }) => (
  <span style={{ color: '#aaa' }} {...props}>
    {children}
  </span>
)

const BreadcrumbSeparator = ({ children, ...props }) => (
  <span className='ant-breadcrumb-link' {...props}>
    {children}
  </span>
)

const BreadcrumbItem = ({ children, ...props }) => (
  <span className='ant-breadcrumb-link pl10 pr10' {...props} >
    {children}
  </span>
)

const Breadcrumb = ({ separator = '/', ...props }) => {
  let children = React.Children.toArray(props.children)

  children = children.map((child, index) => (
    <BreadcrumbItem key={`breadcrumb_item${index}`}>{child}</BreadcrumbItem>
  ))

  const lastIndex = children.length - 1

  children = children.reduce((acc, child, index) => {
    const notLast = index < lastIndex
    if (notLast) {
      acc.push(
        child,
        <BreadcrumbSeparator key={`breadcrumb_sep${index}`}>
          <Separator>{separator}</Separator>
        </BreadcrumbSeparator>,
      )
    } else {
      acc.push(child)
    }
    return acc
  }, [])

  return <div className="ant-breadcrumb pt15">{children}</div>
}

export default Breadcrumb;