import React from 'react';
import logo from '../../../assets/images/logo-sign.png'
import {AZURE_AUTH_URL, SING_IN_ADD} from "../../../constants/common/auth";

const SocialLogin = () => (
  <div className="social-login">
    <a
        className="btn btn-block social-btn icon"
        href={AZURE_AUTH_URL}
        style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
    >
      <img src={logo} alt="Azure"/>{SING_IN_ADD}</a>
  </div>
);

export default SocialLogin;
