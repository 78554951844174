import * as actionTypes from './actionTypes';

import {getAllSpecialitiesByFacultyInIds, getSpecialities, getSpecialitiesByFacultyId} from "../../util/APIUtils";
import {onCatch} from "../../util/helpers";

const fetchStart = () => {
  return {
    type: actionTypes.FETCH_SPECIALITIES_START
  };
};

const fetchSuccess = (list) => {
  return {
    type: actionTypes.FETCH_SPECIALITIES_SUCCESS,
    list
  };
};

const fetchByFacultyIdSuccess = (list) => {
  return {
    type: actionTypes.FETCH_SPECIALITIES_BY_FACULTY_SUCCESS,
    list
  };
};

const fetchFail = (error) => {
  return {
    type: actionTypes.FETCH_SPECIALITIES_FAIL,
    error
  };
};

export const getAllSpecialities = () => {
  return dispatch => {
    dispatch(fetchStart());
    getSpecialities()
      .then(response => {
        dispatch(fetchSuccess(response));
      })
      .catch(error => {
        const m = onCatch(error);
        dispatch(fetchFail(m));
      });
  };
};

export const getAllSpecialitiesByFacultyId = (id) => {
  return dispatch => {
    dispatch(fetchStart());
    getSpecialitiesByFacultyId(id)
      .then(response => {
        dispatch(fetchByFacultyIdSuccess(response));
      })
      .catch(error => {
        const m = onCatch(error);
        dispatch(fetchFail(m));
      });
  };
};

export const getAllSpecialitiesByFacultyIds = (ids) => {
  return dispatch => {
    dispatch(fetchStart());
    getAllSpecialitiesByFacultyInIds(ids)
      .then(response => {
        dispatch(fetchByFacultyIdSuccess(response));
      })
      .catch(error => {
        const m = onCatch(error);
        dispatch(fetchFail(m));
      });
  };
};
