import * as actionTypes from './actionTypes';
import { notification } from 'antd';

import {SUCCESS_SAVED} from '../../constants/common/notification';
import { UsersApi } from '../../util/APIUtils';
import {onCatch} from "../../util/helpers";

const fetchUsersStart = () => {
  return {
    type: actionTypes.FETCH_USERS_START
  };
};

const fetchOneUserSuccess = (object) => {
  return {
    type: actionTypes.FETCH_ONE_USERS_SUCCESS,
    object
  };
};

const fetchUsersFail = (error) => {
  return {
    type: actionTypes.FETCH_USERS_FAIL,
    error
  };
};

const saveUserSuccess = (pathRedirect) => {
  return {
    type: actionTypes.SAVE_USERS_SUCCESS,
    pathRedirect
  };
};

const searchUsersStart = () => {
  return {
    type: actionTypes.SEARCH_USERS_START
  };
};

const searchUsersSuccess = (list) => {
  return {
    type: actionTypes.SEARCH_USERS_SUCCESS,
    list
  };
};

const searchUsersFail = (error) => {
  return {
    type: actionTypes.SEARCH_USERS_FAIL,
    error
  };
};


export const resetUsers = () => {
  return {
    type: actionTypes.RESET_USERS
  };
};

export const getOneUserById = (id) => {
  return dispatch => {
    dispatch(fetchUsersStart());
    UsersApi.getOneById(id)
      .then(response => {
        dispatch(fetchOneUserSuccess(response));
      })
      .catch(error => {
        const m = onCatch(error);
        dispatch(fetchUsersFail(m));
      });
  };
};

export const searchUsers = (filter, page = 0, size) => {
  return dispatch => {
    dispatch(searchUsersStart());
    UsersApi.search(filter, page, size)
      .then(response => {
        dispatch(searchUsersSuccess(response));
      })
      .catch(error => {
        const m = onCatch(error);
        dispatch(searchUsersFail(m));
      });
  };
};

export const saveUser = (id, object) => {
  return dispatch => {
    dispatch(fetchUsersStart());
    UsersApi.save(id, object)
      .then(response => {
        const m = (response && response.response) || '';
        notification.success({
          message: SUCCESS_SAVED,
          description: m
        });
        dispatch(saveUserSuccess('/profile'));
      })
      .catch(error => {
        const m = onCatch(error);
        dispatch(fetchUsersFail(m));
      });
  };
};
