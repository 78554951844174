import * as actionTypes from './actionTypes';

import { getEmploymentTypes } from "../../util/APIUtils";
import {onCatch} from "../../util/helpers";

const fetchStart = () => {
  return {
    type: actionTypes.FETCH_EMPLOYMENT_TYPE_START
  };
};

const fetchSuccess = (list) => {
  return {
    type: actionTypes.FETCH_EMPLOYMENT_TYPE_SUCCESS,
    list
  };
};

const fetchFail = (error) => {
  return {
    type: actionTypes.FETCH_EMPLOYMENT_TYPE_FAIL,
    error
  };
};

export const getAllEmploymentTypes = () => {
  return dispatch => {
    dispatch(fetchStart());
    getEmploymentTypes()
      .then(response => {
        dispatch(fetchSuccess(response));
      })
      .catch(error => {
        const m = onCatch(error);
        dispatch(fetchFail(m));
      });
  };
};
