import {EDITING} from "../common/notification";
import {HOME} from "../common/common";
import {ARCHIVE} from "../common/buttons";
import {EXPIRED_MEETINGS, MEETING, NEW_MEETING, NEW_MEETING_REQUESTS, NEXT_MEETINGS} from "../common/meeting";

const path = {
  create: "/meetings/create",
  view: "/meetings/:id",
  edit: "/meetings/edit/:id",
  createByJobSeeker: "/jobseeker/:id/meetings/create",
  feature: "/meetings/feature",
  requests: "/meetings/requests",
  expired: "/meetings/expired",
  list: "/meetings",
}

const items = {
  create: {
    title: NEW_MEETING,
    path: path.create,
    allowedRoles: ['ADMIN', 'EMPLOYER'],
    breadcrumb: [
      {to: '/', label: HOME},
      {to: path.list, label: MEETING},
      {to: path.create, label: NEW_MEETING}
    ]
  },
  createByJobSeeker: {
    title: NEW_MEETING,
    path: path.createByJobSeeker,
    allowedRoles: ['ADMIN', 'EMPLOYER'],
    breadcrumb: [
      {to: '/', label: HOME},
      {to: path.list, label: MEETING},
      {to: path.createByJobSeeker, label: NEW_MEETING}
    ]
  },
  edit: {
    title: EDITING,
    path: path.edit,
    redirect: path.view,
    allowedRoles: ['ADMIN', 'EMPLOYER'],
    breadcrumb: [
      {to: '/', label: HOME},
      {to: path.list, label: MEETING},
      {to: path.edit, label: EDITING}
    ]
  },
  view: {
    title: MEETING,
    path: path.view,
    redirect: path.edit,
    allowedRoles: ['JOBSEEKER'],
    breadcrumb: [
      {to: '/', label: HOME},
      {to: path.list, label: MEETING}
    ]
  },
  feature: {
    title: NEXT_MEETINGS,
    path: path.feature,
    allowedRoles: ['ADMIN', 'EMPLOYER', 'JOBSEEKER'],
  },
  requests: {
    title: NEW_MEETING_REQUESTS,
    path: path.requests,
    redirect: path.feature,
    allowedRoles: ['ADMIN', 'EMPLOYER'],
  },
  expired: {
    title: EXPIRED_MEETINGS,
    path: path.expired,
    redirect: path.feature,
    allowedRoles: ['ADMIN', 'EMPLOYER'],
  },
  list: {
    title: ARCHIVE,
    path: path.list,
    redirect: path.feature,
    allowedRoles: ['ADMIN', 'EMPLOYER'],
  },
};

export default {path, items}
