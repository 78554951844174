import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../util/utility';

const initialState = {
  pathRedirect: '',
  loading: false,
  error: null,
  object: {}
};

const uploadFileStart = (state, action) => {
  return updateObject(state, {error: null, loading: true});
};

const uploadFileSuccess = (state, action) => {
  return updateObject(state, {
    object: action.object,
    error: null,
    loading: false
  });
};

const downloadFileSuccess = (state, action) => {
  return updateObject(state, {
    [action.file.imageUrl]: action.file.data,
    error: null,
    loading: false
  });
};

const uploadFileFail = (state, action) => {
  return updateObject(state, {
    pathRedirect: action.pathRedirect,
    error: null,
    loading: false
  });
};

const resetUploadFile = (state) => {
  return updateObject(state, initialState);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPLOAD_FILE_START:
      return uploadFileStart(state, action);
    case actionTypes.UPLOAD_FILE_SUCCESS:
      return uploadFileSuccess(state, action);
    case actionTypes.UPLOAD_FILE_FAIL:
      return uploadFileFail(state, action);
    case actionTypes.DOWNLOADED_FILE_SUCCESS:
      return downloadFileSuccess(state, action);
    case actionTypes.UPLOAD_FILE_RESET:
      return resetUploadFile(state, action);
    default:
      return state;
  }
};

export default reducer;
