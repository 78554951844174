import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../util/utility';

const initialState = {
  pathRedirect: '',
  loading: false,
  filter: '',
  error: null,
  object: {},
  list: []
};

const fetchMeetingsStart = (state, action) => {
  return updateObject(state, {error: null, loading: true});
};

const fetchMeetingsSuccess = (state, action) => {
  return updateObject(state, {
    list: action.list,
    error: null,
    loading: false
  });
};

const fetchMeetingsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    list: [],
    loading: false
  });
};

const fetchOneMeetingsSuccess = (state, action) => {
  return updateObject(state, {
    object: action.object,
    error: null,
    loading: false
  });
};

const createMeetingsStart = (state, action) => {
  return updateObject(state, {error: null, loading: true});
};

const createMeetingsSuccess = (state, action) => {
  return updateObject(state, {
    pathRedirect: action.pathRedirect,
    error: null,
    loading: false
  });
};

const saveMeetingsSuccess = (state, action) => {
  return updateObject(state, {
    pathRedirect: action.pathRedirect,
    error: null,
    loading: false
  });
};

const createMeetingsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    list: [],
    loading: false
  });
};

const searchMeetingsStart = (state, action) => {
  return updateObject(state, {error: null, loading: true});
};

const searchMeetingsSuccess = (state, action) => {
  return updateObject(state, {
    list: action.list,
    error: null,
    loading: false
  });
};

const searchMeetingsFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    list: [],
    loading: false
  });
};

const setMeetingsFilter = (state, action) => {
  return updateObject(state, {
    error: null,
    filter: action.filter,
    list: [],
    loading: false
  });
};

const resetMeetings = (state) => {
  return updateObject(state, initialState);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_MEETINGS_START:
      return fetchMeetingsStart(state, action);
    case actionTypes.FETCH_MEETINGS_SUCCESS:
      return fetchMeetingsSuccess(state, action);
    case actionTypes.FETCH_ONE_MEETINGS_SUCCESS:
      return fetchOneMeetingsSuccess(state, action);
    case actionTypes.FETCH_MEETINGS_FAIL:
      return fetchMeetingsFail(state, action);
    case actionTypes.CREATE_MEETINGS_START:
      return createMeetingsStart(state, action);
    case actionTypes.CREATE_MEETINGS_SUCCESS:
      return createMeetingsSuccess(state, action);
    case actionTypes.SAVE_MEETINGS_SUCCESS:
      return saveMeetingsSuccess(state, action);
    case actionTypes.CREATE_MEETINGS_FAIL:
      return createMeetingsFail(state, action);
    case actionTypes.RESET_MEETINGS:
      return resetMeetings(state, action);
    case actionTypes.SET_MEETING_FILTER:
      return setMeetingsFilter(state, action);
    case actionTypes.SEARCH_MEETINGS_START:
      return searchMeetingsStart(state, action);
    case actionTypes.SEARCH_MEETINGS_SUCCESS:
      return searchMeetingsSuccess(state, action);
    case actionTypes.SEARCH_MEETINGS_FAIL:
      return searchMeetingsFail(state, action);
    default:
      return state;
  }
};

export default reducer;
