import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../util/utility';

const initialState = {
  pathRedirect: '',
  loading: false,
  error: null,
  object: {},
  summaryList: [],
  list: []
};

const fetchUsersStart = (state, action) => {
  return updateObject(state, {error: null, loading: true});
};

const fetchOneUserSuccess = (state, action) => {
  return updateObject(state, {
    object: action.object,
    error: null,
    loading: false
  });
};

const saveUsersSuccess = (state, action) => {
  return updateObject(state, {
    pathRedirect: action.pathRedirect,
    error: null,
    loading: false
  });
};

const fetchUsersFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    list: [],
    loading: false
  });
};

const searchUsersStart = (state, action) => {
  return updateObject(state, {error: null, loading: true});
};

const searchUsersSuccess = (state, action) => {
  return updateObject(state, {
    list: action.list,
    error: null,
    loading: false
  });
};

const searchUsersFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    list: [],
    loading: false
  });
};

const resetUsers = (state) => {
  return updateObject(state, initialState);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ONE_USERS_SUCCESS:
      return fetchOneUserSuccess(state, action);
    case actionTypes.SAVE_USERS_SUCCESS:
      return saveUsersSuccess(state, action);
    case actionTypes.FETCH_USERS_START:
      return fetchUsersStart(state, action);
    case actionTypes.FETCH_USERS_FAIL:
      return fetchUsersFail(state, action);
    case actionTypes.SEARCH_USERS_START:
      return searchUsersStart(state, action);
    case actionTypes.SEARCH_USERS_SUCCESS:
      return searchUsersSuccess(state, action);
    case actionTypes.SEARCH_USERS_FAIL:
      return searchUsersFail(state, action);
    case actionTypes.RESET_USERS:
      return resetUsers(state, action);
    default:
      return state;
  }
};

export default reducer;
