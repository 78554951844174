import * as actionTypes from './actionTypes';

import { getEducationLevels } from "../../util/APIUtils";
import {onCatch} from "../../util/helpers";

const fetchStart = () => {
  return {
    type: actionTypes.FETCH_EDUCATION_LEVEL_START
  };
};

const fetchSuccess = (list) => {
  return {
    type: actionTypes.FETCH_EDUCATION_LEVEL_SUCCESS,
    list
  };
};

const fetchFail = (error) => {
  return {
    type: actionTypes.FETCH_EDUCATION_LEVEL_FAIL,
    error
  };
};

export const getAllEducationLevels = () => {
  return dispatch => {
    dispatch(fetchStart());
    getEducationLevels()
      .then(response => {
        dispatch(fetchSuccess(response));
      })
      .catch(error => {
        const m = onCatch(error);
        dispatch(fetchFail(m));
      });
  };
};
