import {EMPTY_DATA} from "./notification";

export const DEPARTMENT = `Навчально-практичний центр кар'єри та професійного зростання`;
export const BRAND = `Донецький національний університет імені Василя Стуса`;
export const BRAND_SHOT = `ДонНУ імені Василя Стуса`;
export const TITLE = `Платформа кар’єри ${BRAND_SHOT}`;
export const FOOTER = `© ${new Date().getFullYear()} ${BRAND}`;
export const LOGO = 'Головна';

export const DATE = 'Дата';
export const RANGE = 'Період';
export const HISTORY = 'Історія';
export const FILTER_ON = 'Показати фільтр';
export const FILTER_OFF = 'Сховати фільтр';
export const FILTER_BY = 'Фільтр по ';

export const JOBSEEKERS = 'Шукачі';
export const JOBSEEKER = 'Кандидат';
export const SEARCH_JOBSEEKERS = 'Пошук кандидатів';
export const EMPLOYERS = 'Роботодавці';
export const BROADCAST = 'Розсилки';
export const REPORTS = 'Звіти';
export const DICTIONARY = 'Словники';
export const HOME = 'Головна';

export const CLOSE = 'Закрити';
export const REOPEN = 'Відкрити повторно';
export const OPEN = 'Відкрити';
export const STATUS  = 'Статус';

export const roles = {
  JOBSEEKER: JOBSEEKER,
  ADMIN: 'Адміністратор',
  EMPLOYER: 'Роботодавець',
}

export const MAIN_MENU_DROPDOWN = [
  {
    key: 'donnu',
    title: `Сайт ${BRAND_SHOT}`,
    url: 'https://www.donnu.edu.ua',
  },
  {
    key: 'news',
    title: `Сайт новин ${BRAND_SHOT}`,
    url: 'https://news.donnu.edu.ua',
  }
];

export const btnIcons = {
  ADD: 'plus',
  MEETINGS: 'idcard',
  VACANCIES: 'book',
  FILTER: 'filter',
  SEARCH: 'search',
  EDIT: 'edit',
  USER: 'user',
};

export const defaultOptions = [
  {
    label: EMPTY_DATA,
    value: ''
  }
];
