import {notification} from 'antd';

import {ERROR, ERROR_DESC, RESPONSES, RESPONSES_DESC} from "../constants/common/apiResponse";

export function formatDate(dateString) {
    const date = new Date(dateString);

    const monthNames = [
      "January", "February", "March",
      "April", "May", "June", "July",
      "August", "September", "October",
      "November", "December"
    ];

    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    return monthNames[monthIndex] + ' ' + year;
}

export function formatDateTime(dateTimeString) {
  const date = new Date(dateTimeString);

  const monthNames = [
    "Jan", "Feb", "Mar", "Apr",
    "May", "Jun", "Jul", "Aug",
    "Sep", "Oct", "Nov", "Dec"
  ];

  const monthIndex = date.getMonth();
  const year = date.getFullYear();

  return date.getDate() + ' ' + monthNames[monthIndex] + ' ' + year + ' - ' + date.getHours() + ':' + date.getMinutes();
}

export const onCatch = (response, customDescription = '') => {
    const {message, description} = badResponseHandler(response);
    notification.error({
        message: message,
        description: customDescription || description
    });
    return message;
}

export const badResponseHandler = (response) => {
    const { message = '', status = '' } = response;
    const m = RESPONSES[status] || ERROR;
    const d = RESPONSES_DESC[message] || message || ERROR_DESC;
    return { message: m, description: d }
}