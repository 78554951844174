import * as actionTypes from './actionTypes';

import {resumePath, employerPath, profilePath} from '../../constants/path';
import {getCurrentUser, login} from '../../util/APIUtils';
import {ACCESS_TOKEN} from '../../constants/common/auth';
import {getRole} from '../../util/userHelpers';
import {onCatch} from '../../util/helpers';
import * as actions from './index';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authSuccess = (token, me = null) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        idToken: token,
        me
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
};

export const logout = () => {
    console.log('[ logout ]');
    localStorage.removeItem(ACCESS_TOKEN);
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000);
    };
};

export const setAuthRedirectPath = (path) => {
    return {
        type: actionTypes.SET_AUTH_REDIRECT_PATH,
        path: path
    };
};

export const authCheckState = () => {
    console.log('[ auth check ]');
    return dispatch => {
        dispatch(authStart());
        const token = localStorage.getItem(ACCESS_TOKEN);
        if (!token) {
            dispatch(logout());
        } else {
            getCurrentUser()
                .then(response => {
                    const role = getRole(response)
                    if (role === 'JOBSEEKER') {
                        dispatch(
                            actions.getActiveOneJobSeekerByUserId(
                                response.id,
                                profilePath.path.view,
                                [resumePath.path.create]
                            )
                        )
                    }
                    if (role === 'EMPLOYER') {
                        dispatch(
                            actions.getActiveOneEmployerByUserId(
                                response.id,
                                profilePath.path.view,
                                [employerPath.path.create]
                            )
                        )
                    }
                    dispatch(authSuccess(token, response));
                }).catch(error => {
                    const m = onCatch(error);
                    dispatch(logout());
                    dispatch(authFail(m));
                })
        }
    };
};

export const auth = (loginRequest) => {
    console.log('[ auth ]');
    return dispatch => {
        dispatch(authStart());
        login(loginRequest)
            .then(response => {
                localStorage.setItem(ACCESS_TOKEN, response.accessToken);
                dispatch(authSuccess(response.accessToken));
                dispatch(authCheckState());
            })
            .catch(error => {
                const m = onCatch(error);
                dispatch(authFail(m));
            });
    };
};
