import React from 'react';
import thunk from 'redux-thunk';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { createResponsiveStateReducer, responsiveStoreEnhancer } from 'redux-responsive';

// import registerServiceWorker from './registerServiceWorker';
import * as actions from "./store/actions/auth";
import authReducer from './store/reducers/auth';
import jobSeekerReducer from './store/reducers/jobSeeker';

import employmentStabilityReducer from './store/reducers/employmentStability';
import educationLevelReducer from './store/reducers/educationLevel';
import employmentTypeReducer from './store/reducers/employmentType';
import educationYearsReducer from './store/reducers/educationYear';
import educationFormReducer from './store/reducers/educationForm';
import experienceReducer from './store/reducers/experience';
import specialityReducer from './store/reducers/speciality';
import uploadFileReducer from './store/reducers/uploadFile';
import categoryReducer from './store/reducers/category';
import employerReducer from './store/reducers/employer';
import meetingReducer from './store/reducers/meeting';
import facultyReducer from './store/reducers/faculty';
import vacancyReducer from './store/reducers/vacancy';
import pageReducer from './store/reducers/page';
import userReducer from './store/reducers/user';
import App from './App';

import './index.css';

const rootReducer = combineReducers({
  auth: authReducer,
  page: pageReducer,
  user: userReducer,
  faculty: facultyReducer,
  vacancy: vacancyReducer,
  meeting: meetingReducer,
  category: categoryReducer,
  employer: employerReducer,
  jobSeeker: jobSeekerReducer,
  uploadFile: uploadFileReducer,
  speciality: specialityReducer,
  experience: experienceReducer,
  educationForm: educationFormReducer,
  educationYear: educationYearsReducer,
  employmentType: employmentTypeReducer,
  educationLevel: educationLevelReducer,
  employmentStability: employmentStabilityReducer,
  browser: createResponsiveStateReducer({extraSmall: 480, small: 768, medium: 992, large: 1200})
});

const store = createStore(
  rootReducer,
  compose(
    responsiveStoreEnhancer,
    applyMiddleware(thunk)
  )
);

const app = (
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

store.dispatch(actions.authCheckState());
ReactDOM.render( app, document.getElementById( 'root' ) );
// registerServiceWorker();
