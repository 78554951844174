import * as actionTypes from './actionTypes';

const setActions = (list) => {
  return {
    type: actionTypes.SET_PAGE_ACTIONS,
    list
  };
};

const setTags = (list) => {
  return {
    type: actionTypes.SET_PAGE_TAGS,
    list
  };
};

const setPathRedirect = (value) => {
  return {
    type: actionTypes.SET_PAGE_PATH_REDIRECT,
    value
  };
};

const resetTags = () => {
  return {
    type: actionTypes.RESET_PAGE_TAGS
  };
};

const resetActions = () => {
  return {
    type: actionTypes.RESET_PAGE_ACTIONS
  };
};

const resetPathRedirect = () => {
  return {
    type: actionTypes.RESET_PAGE_PATH_REDIRECT
  };
};

const reset = () => {
  return {
    type: actionTypes.RESET_PAGE
  };
};

export const setPageActions = (list) => {
  return dispatch => {
    dispatch(setActions(list));
  };
};

export const setPagePathRedirect = (value) => {
  return dispatch => {
    dispatch(setPathRedirect(value));
  };
};

export const setPageTags = (list) => {
  return dispatch => {
    dispatch(setTags(list));
  };
};


export const resetPageTags = () => {
  return dispatch => {
    dispatch(resetTags());
  };
};

export const resetPageActions = () => {
  return dispatch => {
    dispatch(resetActions());
  };
};

export const resetPagePathRedirect = () => {
  return dispatch => {
    dispatch(resetPathRedirect());
  };
};

export const resetPage = () => {
  return dispatch => {
    dispatch(reset());
  };
};
