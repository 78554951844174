export const POSITION = 'Назва вакансії'
export const SHORT_POSITION = 'Вакансія'
export const NEW_MEETING_REQUESTS = 'Нові заявки'
export const EXPIRED_MEETINGS = 'Потребують оновлення'
export const EXPIRED_DATE = 'Термін закінчився'
export const NEXT_MEETINGS = 'Найближчі співбесіди'
export const SUCCESS_MEETINGS = 'Прийняті'
export const REJECTED_MEETINGS = 'Відхилені'
export const SET_MEETING_REJECTED = 'Відхилити'
export const NOT_HAPPENED_MEETINGS = 'Не відбулися'
export const CREATE_VACANCY_REQUEST = 'Створити запит'

export const MEETING_CREATE = 'Назначити співбесіду';
export const NEW_MEETING = 'Нова співбесіда';
export const MEETING_SUGGEST = 'Запропонувати вакансію';
export const MEETING_DATE = 'Час співбесіди';
export const MEETING = 'Cпівбесіда';
export const MEETINGS = 'Cпівбесіди';
export const MEETING_STATUS = 'Статус';
export const NOT_HAPPENED = 'Співбесіда не відбулася';
export const INTERVIEW = 'Назначено співбесіду';
export const HIRED = 'Прийнято';
export const REJECTED = 'Не прийнято';
export const PENDING = 'Очікує запрошення на співбесіду';
export const PENDING_INTERVIEW = 'Запит вже відправлено';
export const MEETING_EXPIRED = 'Термін закінчився. Будь ласка, прийміть рішення щодо результату співбесіди';

export const ALLOWED_MEETING_STATUS = [
  {
    label: NOT_HAPPENED,
    value: 'NOT_HAPPENED'
  },
  {
    label: INTERVIEW,
    value: 'INTERVIEW'
  },
  {
    label: HIRED,
    value: 'HIRED'
  },
  {
    label: REJECTED,
    value: 'REJECTED'
  },
  {
    label: PENDING,
    value: 'PENDING'
  }
];

export const MEETING_STATUS_TYPE = {
  NOT_HAPPENED: 'warning',
  HIRED: 'success',
  PENDING: 'info',
  REJECTED: 'error'
};

export const MEETING_STATUS_COLOR = {
  NOT_HAPPENED: 'red',
  HIRED: 'green',
  PENDING: 'blue',
  INTERVIEW: 'blue',
  REJECTED: 'red'
};

export const MEETING_STATUS_LIST = {
  NOT_HAPPENED: 'NOT_HAPPENED',
  INTERVIEW: 'INTERVIEW',
  HIRED: 'HIRED',
  REJECTED: 'REJECTED',
  PENDING: 'PENDING',
};

export const MEETING_STATUS_VALUES = {
  NOT_HAPPENED,
  INTERVIEW,
  HIRED,
  REJECTED,
  PENDING
};
