import * as actionTypes from '../actions/actionTypes';
import {updateObject} from '../../util/utility';

const initialState = {
  pathRedirect: '',
  summaryList: [],
  loading: false,
  error: null,
  object: {},
  list: []
};

const fetchJobSeekersSummaryStart = (state, action) => {
  return updateObject(state, {error: null, loading: true});
};

const fetchJobSeekersSummarySuccess = (state, action) => {
  return updateObject(state, {
    summaryList: action.list,
    error: null,
    loading: false
  });
};

const fetchJobSeekersSummaryFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    summaryList: [],
    loading: false
  });
};

const fetchJobSeekersStart = (state, action) => {
  return updateObject(state, {error: null, loading: true});
};

const fetchJobSeekersSuccess = (state, action) => {
  return updateObject(state, {
    list: action.list,
    error: null,
    loading: false
  });
};

const fetchOneJobSeekersSuccess = (state, action) => {
  return updateObject(state, {
    object: action.object,
    error: null,
    loading: false
  });
};

const fetchJobSeekersFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    list: [],
    loading: false
  });
};

const createJobSeekersStart = (state, action) => {
  return updateObject(state, {error: null, loading: true});
};

const createJobSeekersSuccess = (state, action) => {
  return updateObject(state, {
    pathRedirect: action.pathRedirect,
    error: null,
    loading: false
  });
};

const saveJobSeekersSuccess = (state, action) => {
  return updateObject(state, {
    pathRedirect: action.pathRedirect,
    error: null,
    loading: false
  });
};

const createJobSeekersFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    list: [],
    loading: false
  });
};

const searchJobSeekersStart = (state, action) => {
  return updateObject(state, {error: null, loading: true});
};

const searchJobSeekersSuccess = (state, action) => {
  return updateObject(state, {
    list: action.list,
    error: null,
    loading: false
  });
};

const searchJobSeekersFail = (state, action) => {
  return updateObject(state, {
    error: action.error,
    list: [],
    loading: false
  });
};

const resetJobSeekers = (state) => {
  return updateObject(state, initialState);
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_JOB_SEEKERS_START:
      return fetchJobSeekersStart(state, action);
    case actionTypes.FETCH_JOB_SEEKERS_SUCCESS:
      return fetchJobSeekersSuccess(state, action);
    case actionTypes.FETCH_ONE_JOB_SEEKERS_SUCCESS:
      return fetchOneJobSeekersSuccess(state, action);
    case actionTypes.FETCH_JOB_SEEKERS_FAIL:
      return fetchJobSeekersFail(state, action);
    case actionTypes.CREATE_JOB_SEEKERS_START:
      return createJobSeekersStart(state, action);
    case actionTypes.CREATE_JOB_SEEKERS_SUCCESS:
      return createJobSeekersSuccess(state, action);
    case actionTypes.SAVE_JOB_SEEKERS_SUCCESS:
      return saveJobSeekersSuccess(state, action);
    case actionTypes.CREATE_JOB_SEEKERS_FAIL:
      return createJobSeekersFail(state, action);
    case actionTypes.RESET_JOB_SEEKERS:
      return resetJobSeekers(state, action);
    case actionTypes.FETCH_JOB_SEEKERS_SUMMARY_START:
      return fetchJobSeekersSummaryStart(state, action);
    case actionTypes.FETCH_JOB_SEEKERS_SUMMARY_SUCCESS:
      return fetchJobSeekersSummarySuccess(state, action);
    case actionTypes.FETCH_JOB_SEEKERS_SUMMARY_FAIL:
      return fetchJobSeekersSummaryFail(state, action);
    case actionTypes.SEARCH_JOB_SEEKERS_START:
      return searchJobSeekersStart(state, action);
    case actionTypes.SEARCH_JOB_SEEKERS_SUCCESS:
      return searchJobSeekersSuccess(state, action);
    case actionTypes.SEARCH_JOB_SEEKERS_FAIL:
      return searchJobSeekersFail(state, action);
    default:
      return state;
  }
};

export default reducer;